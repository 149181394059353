import { Icon } from "@material-ui/core";

const ItemsMenuMaq = [
    {
        title: 'Ver Mantenimientos Realizados',
        path: 'listaMantenimientos',
        Icon: () => <Icon className="fas icon fa-tools"></Icon>
    },
    {
        title: 'Registrar Mantenimiento',
        path: 'guardarMantenimiento',
        Icon: () => <Icon className="fas icon fa-check-to-slot"></Icon>
    },
    {
        title: 'Ver Refacciones',
        path: 'listaRefacciones',
        Icon: () => <Icon className="fas icon fa-toolbox"></Icon>
    }
    // Puedes agregar más elementos de menú aquí según sea necesario
  ];
  
  export default ItemsMenuMaq;
  