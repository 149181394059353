import React from 'react';
import Servidor from './Servidor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, SwipeableDrawer, List, Divider, Icon } from '@material-ui/core';

class RefacEditWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      right: false,
      refaccion: {
        action: "guardar cambios",
        id_ref: this.props.id_ref,
        nom_refac: "",
        fecha_capt: new Date().toISOString().slice(0, 10),
        cant: '',
        agregar: '',
        costo_refac: "",
      }
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.list = this.list.bind(this);
    this.manejarCambio = this.manejarCambio.bind(this);
    this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
  }

  toggleDrawer(anchor, open) {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      this.setState({ [anchor]: open });
    };
  }

  async componentDidMount() {
    const id = this.props.id_ref;
    if (!id) return;
    try {
        const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacciones.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                action: 'obtener refaccion',
                id: id
            })
        });
        const refaccionData = await respuesta.json();
        if (refaccionData.length > 0) {
            const refaccion = refaccionData[0];  // Selecciona el primer elemento del array
            refaccion.action = "guardar cambios";
            this.setState({ refaccion: refaccion });
        }
    } catch (error) {
        console.error("Error al obtener la refacción:", error);
    }
  }

  list(anchor) {
    this.state.refaccion.fecha_capt = new Date().toISOString().slice(0, 10);
    return (
      <div
        className="has-background-grey p-2"
        style={{ width: '100%', height: '150%' }}
        role="presentation"
        onClick={this.toggleDrawer(anchor, false)}
        onKeyDown={this.toggleDrawer(anchor, false)}
      >
        <List style={{ height: '100%' }}>
          <form className="card-body p-3" onSubmit={this.manejarEnvioDeFormulario} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <h1 className="card-header title"><p className="card-header-title is-size-4">Editar Refacciones</p></h1>
            <ToastContainer />
            <div className="control">
              <label className='label' htmlFor="nom_refac"> Nombre</label>
              <input
                readOnly
                placeholder="Nombre"
                type="text"
                name='nom_refac'
                id="nom_refac"
                onChange={this.manejarCambio}
                value={this.state.refaccion.nom_refac}
                className="input is-rounded is-hovered is-warning"
              />
            </div>
            <div className="control mt-3">
              <label className='label' htmlFor="fecha_capt"> Fecha de Captura</label>
              <input
                required
                placeholder="Fecha Captura" 
                type="date" 
                name='fecha_capt'
                id="fecha_capt" 
                onChange={this.manejarCambio} 
                value={this.state.refaccion.fecha_capt || ''} 
                className="input is-rounded is-hovered is-warning" 
              />
            </div>
            <div className="control mt-3">
              <label className='label' htmlFor="cant"> Cantidad en Existencia</label>
              <input
                readOnly
                required
                placeholder="Cantidad en existencia"
                type="number"
                name="cant"
                id="cant"
                onChange={this.manejarCambio}
                value={this.state.refaccion.cant}
                className="input is-rounded is-hovered is-warning"
              />
            </div>
            <div className="control mt-3">
              <label className='label' htmlFor="agregar"> Agregar a Existencia</label>
              <input
                required
                placeholder="Agregar a existencia"
                type="number"
                name="agregar"
                id="agregar"
                onChange={this.manejarCambio}
                value={this.state.refaccion.agregar}
                className="input is-rounded is-hovered is-warning"
              />
            </div>
            <div className="control mt-3">
              <label className='label' htmlFor="costo_refac"> Costo por unidad</label>
              <input
                required
                placeholder="Costo (por unidad)"
                type="number"
                name="costo_refac"
                id="costo_refac"
                onChange={this.manejarCambio}
                value={this.state.refaccion.costo_refac}
                className="input is-rounded is-hovered is-warning"
              />
            </div>
            <input type="hidden" name="action" value={this.state.refaccion.action} />
            <input type="hidden" name="id" value={this.state.refaccion.id_ref} />
            <Divider style={{ backgroundColor: 'white' }} className='mt-4' />
            <div className="form-group">
              <button className="button is-warning is-rounded is-inverted mt-3"><i className="fas fa-save mr-2 icon"></i> Guardar</button>
            </div>
          </form>
        </List>
      </div>
    );
  }

  async manejarEnvioDeFormulario(evento) {
    evento.preventDefault();
    const cargaUtil = JSON.stringify(this.state.refaccion);
    const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacciones.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: cargaUtil
    });
    const exitoso = await respuesta.json();
    if (exitoso === true) {
      toast('Refacción Actualizada 🧰', {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({
        refaccion: {
          action: 'guardar cambios',
          id_ref: this.props.id_ref,
          nom_refac: "",
          fecha_capt: new Date().toISOString().slice(0, 10),
          cant: '',
          agregar: '',
          costo_refac: "",
        }
      });
    } else {
      toast.error('Error al Actualizar Refacción 🧰');
    }
  }

  manejarCambio(evento) {
    const clave = evento.target.name;
    let valor = evento.target.value;
    this.setState(state => {
      const refaccionActualizada = { ...state.refaccion, [clave]: valor };
      return { refaccion: refaccionActualizada };
    });
  }

  render() {
    return (
      <div className='button is-info btn-icon'>
        <ToastContainer />
        <Button className='button btn-icon' onClick={this.toggleDrawer('right', true)}><Icon className="fas fa-square-plus"></Icon></Button>
        <SwipeableDrawer
          anchor={'right'}
          open={this.state['right']}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          {this.list('right')}
        </SwipeableDrawer>
      </div>
    );
  }
}

export default RefacEditWindow;