import React, { useState, useEffect } from 'react';
import Servidor from "../components/Servidor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BarraPage from './BarraPage';
import ItemsRefac from '../components/ItemsRefac';
import RefacWindow from '../components/RefacWindow';
import { infoListaRefac } from '../utils/helpers';
import { Link } from 'react-router-dom';

function ListaRefac() {
    const token = localStorage.getItem("token");
    const [refacciones, setRefacciones] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalGastos, setTotalGastos] = useState(0);
    const rol = localStorage.getItem('rol');

    useEffect(() => {
        async function fetchRefacciones() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacciones.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setRefacciones(data);
        }
        fetchRefacciones();
    }, []);

    useEffect(() => {
        const total = refacciones.reduce((accumulator, current) => 
            accumulator + (parseFloat(current.costo_refac) || 0) * (parseFloat(current.cant) || 0), 
            0
        );
        setTotalGastos(total);
    }, [refacciones]);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        const total = refacciones
            .filter((refaccion) => {
                return (
                    refaccion.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    refaccion.nom_refac.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    refaccion.fecha_capt.includes(searchTerm.toLowerCase()) ||
                    refaccion.cant.includes(searchTerm.toLowerCase()) ||
                    refaccion.costo_refac.includes(searchTerm.toLowerCase())
                );
            })
            .reduce((accumulator, current) => 
                accumulator + (parseFloat(current.costo_refac) || 0) * (parseFloat(current.cant) || 0), 
                0
            );
        setTotalGastos(total);
    }, [searchTerm, refacciones]);

    if (!token) {
        return <div className="notification is-info"><h1 className="title">Necesitas iniciar sesión para poder entrar o tu usuario no tiene permisos para entrar aquí</h1></div>;
    }

    let agregar = (rol === 'Admin' || rol === 'sudo') ? <RefacWindow /> : null
    return (
        <div className="card has-background-grey p-4" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            <button id="datos" onClick={infoListaRefac} className="button btn-icon is-primary is-inverted is-outlined"><i className="fas fa-question fa-flip"></i></button>
            <Link to="/capturainv"><button style={{ right: "3rem", bottom: "2rem", width: "15.5rem" }} className="button datos btn-icon is-light is-inverted is-outlined"><i className="fa-brands fa-dropbox fa-bounce mr-2"></i> Capturar Inventario</button></Link>
            {agregar}
            <br />
            <div id='mantenimientosall' className="card mb-1" style={{ margin: 0 }}>
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th style={{ width: "9%" }}><i className="icon is-medium fas fa-arrow-down-1-9"></i> ID</th>
                            <th><i className="icon is-medium fas fa-toolbox"></i>Nombre</th>
                            <th style={{ width: "12%" }}><i className="icon is-medium fas fa-hashtag"></i>Cantidad</th>
                            <th style={{ width: "12%" }}><i className="icon is-medium fas fa-dollar-sign"></i>Costo</th>
                            <th style={{ width: "25%" }}><i className="icon is-medium fas fa-calendar-alt"></i>Fecha Captura (Última vez)</th>
                            <th style={{ width: "15%" }}><i className="icon is-medium fas fa-gear"></i> Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {refacciones
                            .filter((refaccion) => 
                                refaccion.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.nom_refac.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.fecha_capt.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.cant.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.costo_refac.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((refaccion) => (
                                <ItemsRefac key={refaccion.id} refaccion={refaccion} />
                            ))}
                    </tbody>
                </table>
            </div>
            <h1 className='tag has-text-dark is-warning title is-5' style={{ margin: 0, width: "100%"}}> 
                Total de Gastos: ${totalGastos}
            </h1>
        </div>
    );
}

export default ListaRefac;
