import React from "react";
import Servidor from "./Servidor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ConsumirRefac from "./ConsumirRefac";
import RefacEditWindow from "./RefacEditWindow";

class ItemsRefac extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eliminado: false
        };
        this.Eliminar = this.Eliminar.bind(this);
    }
    async Eliminar(id) {
        const url = `${Servidor.url}/controller/ControladorRefacciones.php`;
        const resultado = await Swal.fire({
            title: '¿Deseas eliminar esta Refacción?',
            text: "No podrás recuperar esta información",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'No'
        });
        if (!resultado.value){
            return;
        }
        const respuesta = await fetch(url, {
            method: "DELETE",
            body: JSON.stringify({
                action: "eliminar", // Identifica la acción como eliminar
                id: id, // Envía el ID de la maquinaria a eliminar
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const exitoso = await respuesta.json();
        if (exitoso === null) {
            toast('Refacción Eliminada 🛠️ ', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                eliminado: true,
            });
        } else {
            toast.error("Error eliminando la refacción. Intenta de nuevo");
        }
    }

    render() {
        if (this.state.eliminado) {
            return null;
        }
        let sumaCosto = parseFloat(this.props.refaccion.costo_refac) * parseFloat(this.props.refaccion.cant);
        const rol = localStorage.getItem('rol') || ''; // Asigna un valor por defecto si 'rol' es null o undefined
        const botones = (rol === 'Admin' || rol === 'sudo') ? <td><button className="button btn-icon is-danger" onClick={() => this.Eliminar(this.props.refaccion.id)}><i className="fas fa-trash"></i></button><RefacEditWindow id_ref={this.props.refaccion.id} /><ConsumirRefac id_ref={this.props.refaccion.id} nom_refac={this.props.refaccion.nom_refac} costo_refac={this.props.refaccion.costo_refac} /></td> : <td>No tienes permisos</td>;
        return (
            <tr>
                <td>{this.props.refaccion.id}</td>
                <td>{this.props.refaccion.nom_refac}</td>
                <td>{this.props.refaccion.cant}</td>
                <td><p className="tag is-success is-medium has-text-weight-bold"><i className="fas fa-dollar-sign icon"></i> {sumaCosto}</p></td>
                <td>{this.props.refaccion.fecha_capt}</td>
                    {botones}
            </tr>
        );
    }
}

export default ItemsRefac;