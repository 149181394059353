import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import getMenuItems from '../components/menuItems';
import UserModal from './login/UserModal';
import MenuDesc from './MenuDesc';

const Menu = ({isLoggedIn}) => {
  const items = getMenuItems(isLoggedIn);
  const nom_usu = localStorage.getItem('nom_usu');
  const rol = localStorage.getItem('rol');
  let CrearUsuario = (rol === 'sudo') ? <UserModal /> : null
  const MenuDescargas = (!isLoggedIn) ? null : <MenuDesc />
  
  return (
    <div id='menu'>
      <aside className='menu p-1'>
        <img src="./logo fresh.png" className="App-logo" alt="" />
        <p className="menu-label">Menú <br />{isLoggedIn ? <b className='tag is-primary'>{nom_usu}</b> : <b style={{width: '100%', display: 'flex', justifyContent: 'center', fontSize: '70%'}} className='tag is-warning is-small'>Aparecerá cuando inicies sesión</b> } </p>
        <hr className="has-background-grey-light" />
        <ul className="menu-list">
          {items.map((item) => (
            <li key={item.title}>
              {item.onClick ? (
                <button style={{ color: 'red', display: 'flex', border: '1px solid red', justifyContent: 'center', alignItems: 'center' }} className="mb-3" onClick={item.onClick}>
                  <item.Icon /> {item.title}
                </button>
              ) : (
                <Link className="mb-3" to={item.path}>
                  <item.Icon /> {item.title}
                </Link>
              )}
            </li>
          ))}
          {MenuDescargas}
          <hr className="has-background-grey-light" />
          {CrearUsuario}
        </ul>
      </aside>
    </div>
  );
}

export default Menu;
