import React from 'react';
import * as XLSX from 'xlsx';
import Servidor from '../Servidor';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';

const styles = (theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: 'rgba(130, 130, 130, 0.8)',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '0.5rem',
    },
});

class DescargarInv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            fechas: {
                dia: new Date().toISOString().split('T')[0],
                action: 'descargar dif',
            },
            diferencia: [],
            fechasUnicas: [],
            fechaSeleccionada: null,
        };
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    async componentDidMount() {
        try {
            const response = await fetch(`${Servidor.url}/controller/ControladorDiferencia.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'obtener' }),
            });
            const diferencia = await response.json();
            const fechasUnicas = [...new Set(diferencia.map(item => item.fecha_captura))];
            this.setState({ diferencia, fechasUnicas });
        } catch (error) {
            console.error('Error al obtener la diferencia:', error);
        }
    }

    manejarCambio = (event, newValue) => {
        if (newValue) {
            this.setState({
                fechas: { ...this.state.fechas, dia: newValue.value },
                fechaSeleccionada: newValue
            });
        } else {
            this.setState({
                fechas: { ...this.state.fechas, dia: '' },
                fechaSeleccionada: null
            });
        }
    }

    manejarEnvioDeFormulario = async (e) => {
        e.preventDefault();
        const { fechas } = this.state;
        try {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorDiferencia.php`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(fechas),
            });
            const data = await respuesta.json();
            if (data && data.length > 0) {
                this.convertirAExcel(data);
                toast.success('Inventario descargado 🧰', { position: "top-left", autoClose: 2000 });
            } else {
                toast.error('Error ❌ Posiblemente no hay inventario para descargar 🤔', { position: "top-left", autoClose: 2000 });
            }
        } catch (error) {
            toast.error('Error ❌ No se pudo descargar el inventario', { position: "top-left", autoClose: 2000 });
            console.error('Error al enviar el formulario:', error);
        }
    }

    convertirAExcel = (data) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, 'Mantenimientos');
        XLSX.writeFile(wb, `Inventario ${this.state.fechas.dia}.xlsx`);
    }

    render() {
        const { classes } = this.props;
        const { open, fechasUnicas, fechaSeleccionada } = this.state;
        const fechasOptions = fechasUnicas.map((dia) => ({ label: dia, value: dia }));

        return (
            <div style={{ width: '100%' }}>
                <button style={{ width: '100%' }} className="button is-info is-inverted is-outlined" type="button" onClick={this.handleOpen}>
                    <i className="fa-brands fa-dropbox mr-2"></i>Inventarios
                </button>
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <h1 className="card-header title">
                                <p className="has-text-light">Ingresa el día que <br /> deseas descargar</p>
                            </h1>
                            <form className="card-body p-3" onSubmit={this.manejarEnvioDeFormulario}>
                                <Autocomplete
                                    id="small-outlined"
                                    options={fechasOptions}
                                    style={{ backgroundColor: 'white', borderRadius: '0.5rem' }}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    value={fechaSeleccionada}
                                    onChange={this.manejarCambio}
                                    renderInput={(params) => <TextField {...params} label="Fecha" variant="outlined" />}
                                />
                                <ToastContainer />
                                <button className="button is-info is-inverted is-outlined mt-3" type="submit">
                                    <i className="fa fa-download mr-2"></i>Descargar
                                </button>
                            </form>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

export default withStyles(styles)(DescargarInv);
