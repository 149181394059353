import React from 'react';
import Servidor from './Servidor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, SwipeableDrawer, List, Divider, Icon } from '@material-ui/core';

class RefacWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
      refaccion: {
        action: "guardar",
        nom_refac: "",
        fecha_capt: new Date().toISOString().slice(0, 10),
        cant: '',
        costo_refac: "",
      }
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.list = this.list.bind(this);
    this.manejarCambio = this.manejarCambio.bind(this);
    this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
  }

  toggleDrawer(anchor, open) {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      this.setState({ ...this.state, [anchor]: open });
    };
  }

  list(anchor) {
    return (
      <div
        className="has-background-dark p-2"
        style={{ width: '100%', height: '100%' }}
        role="presentation"
        onClick={this.toggleDrawer(anchor, false)}
        onKeyDown={this.toggleDrawer(anchor, false)}
      >
        <List style={{ height: '100%' }}>
            <form className="card-body p-3" onSubmit={this.manejarEnvioDeFormulario} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <h1 className="card-header title"><p className="card-header-title is-size-4">Guardar Refacciones</p></h1>
                <ToastContainer></ToastContainer>
                <div className="control">
                    <input 
                        required 
                        placeholder="Nombre" 
                        type="text" 
                        name='nom_refac'
                        id="nom_refac" 
                        onChange={this.manejarCambio} 
                        value={this.state.refaccion.nom_refac || ''} 
                        className="input is-rounded is-hovered is-warning" 
                    />
                </div>
                <div className="control mt-3">
                    <input
                        required
                        placeholder="Fecha Captura" 
                        type="date" 
                        name='fecha_capt'
                        id="fecha_capt" 
                        onChange={this.manejarCambio} 
                        value={this.state.refaccion.fecha_capt || ''} 
                        className="input is-rounded is-hovered is-warning" 
                    />
                </div>
                <div className="control mt-3">
                    <input
                        required
                        placeholder="Cantidad"
                        type="number"
                        name="cant"
                        id="cant"
                        onChange={this.manejarCambio}
                        value={this.state.refaccion.cant || ''}
                        className="input is-rounded is-hovered is-warning"
                    />
                </div>
                <div className="control mt-3">
                    <input
                        required
                        placeholder="Costo (por unidad)"
                        type="number"
                        name="costo_refac"
                        id="costo_refac"
                        onChange={this.manejarCambio}
                        value={this.state.refaccion.costo_refac || ''}
                        className="input is-rounded is-hovered is-warning"
                    />
                </div>
                <input type="hidden" name="action" value={this.state.refaccion.action || 'guardar'} />
                <Divider style={{ backgroundColor: 'white' }} className='mt-4' />
                <div className="form-group">
                    <button className="button is-warning is-rounded is-inverted mt-3"><i className="fas fa-save mr-2 icon"></i> Guardar</button>
                    &nbsp;
                </div>
            </form>
        </List>
      </div>
    );
  }

  render() {
    return (
      <div id='add' className='button is-warning is-inverted is-outlined btn-icon'>
        <ToastContainer />
        <Button className='button' style={{ color: 'yellow' }} onClick={this.toggleDrawer('left', true)}><Icon className="fas fa-toolbox icon"></Icon></Button>
        <SwipeableDrawer
          anchor={'left'}
          open={this.state['left']}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          {this.list('left')}
        </SwipeableDrawer>
      </div>
    );
  }

  async manejarEnvioDeFormulario(evento) {
    evento.preventDefault(); // Corrige la función preventDefault

    const cargaUtil = JSON.stringify(this.state.refaccion);
    try {
      const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacciones.php`, {
        method: "POST",
        body: cargaUtil,
      });
      const exitoso = await respuesta.json();
      if (exitoso === true) {
        toast('Refacción Guardada 🧰', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          refaccion: {
            action: "guardar",
            nom_refac: "",
            fecha_capt: new Date().toISOString().slice(0, 10),
            cant: '',
            costo_refac: "",
          },
        });
      } else {
        toast.error("Error al Guardar Refacción 🧰");
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      toast.error("Error de conexión. Cargando Refacciones 🧰...");
    };
  }

  manejarCambio(evento) {
      const clave = evento.target.id;
      let valor = evento.target.value;
      this.setState(state => {
          const refaccionActualizada = state.refaccion;
          if (clave === 'costo_refac' || clave === 'cant') {
              valor = parseFloat(valor);
          }
          refaccionActualizada[clave] = valor;
          return { refaccion: refaccionActualizada };
      });
  }
}

export default RefacWindow;
