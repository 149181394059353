import React from 'react';
import * as XLSX from 'xlsx';
import Servidor from '../Servidor';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Icon } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgba(130, 130, 130, 0.8)',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '0.5rem',
  },
});

class DescargarMants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fechas: {
        start: new Date().toISOString().split('T')[0], // Fecha actual en formato YYYY-MM-DD
        end: new Date().toISOString().split('T')[0],
        action: 'descargar excel',
      }
    };
    this.manejarCambio = this.manejarCambio.bind(this);
    this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
        <div style={{ width: '100%' }}>
            <button style={{ width: '100%' }} className="button is-success is-inverted is-outlined" type="button" onClick={this.handleOpen}>
                <i className="fa fa-cogs mr-2"></i>Mantenimientos
            </button>
            <Modal
            className={classes.modal}
            open={open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h1 className="card-header title"><p className="card-header-title has-text-light">Ingresa las fechas</p></h1>
                        <form className="card-body p-3" onSubmit={this.manejarEnvioDeFormulario}>
                            <ToastContainer />
                            <div class="field">
                                <input type="date" 
                                    name="start"
                                    id="start"
                                    onChange={this.manejarCambio}
                                    value={this.state.fechas.start}
                                    className='input is-hovered is-warning has-background-grey'
                                />
                            </div>
                            <div class="field">
                                <input type="date" 
                                    name="end"
                                    id="end"
                                    onChange={this.manejarCambio}
                                    value={this.state.fechas.end}
                                    className='input is-hovered is-warning has-background-grey'
                                />
                            </div>
                            <button className="button is-success is-inverted is-outlined" type="submit"><i className="fa fa-download mr-2"></i>Descargar</button>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
        );
    }

    async manejarEnvioDeFormulario(e) {
        e.preventDefault();
        const cargaUtil = JSON.stringify(this.state.fechas);
        const respuesta = await fetch(`${Servidor.url}/controller/ControladorMantenimiento.php`, {
            method: "POST",
            body: cargaUtil,
        });
    
        // Verificar si la respuesta ya ha sido leída antes de intentar leerla como JSON
        const exitoso = await respuesta.json();
        if (exitoso != null) {
            this.convertiraExcel(exitoso);
            toast('Listado descargado 🛠️', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                fechas: {
                    start: new Date().toISOString().split('T')[0],
                    end: new Date().toISOString().split('T')[0],
                    action: 'descargar excel',
                }
            });
        } else {
            toast('Error al descargar ❌')
        }
    }
    
    convertiraExcel(exitoso) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(exitoso);
        XLSX.utils.book_append_sheet(wb, ws, 'Mantenimientos');
        XLSX.writeFile(wb, 'Mantenimientos Del ' + this.state.fechas.start + ' Al ' + this.state.fechas.end + '.xlsx');
    }    

    manejarCambio(e) {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            fechas: { ...prevState.fechas, [name]: value }
        }));
    }
}

export default withStyles(styles)(DescargarMants);
