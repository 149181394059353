import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Typography, Icon } from '@material-ui/core';
import Servidor from '../../components/Servidor';

function ViewActividades(props) {
    const [mantenimientos, setMantenimientos] = useState([]);
    const [actividadesConContador, setActividadesConContador] = useState([]);
    const [reloadView, setReloadView] = useState(false);
    const [nombreMes, setNombreMes] = useState('');

    useEffect(() => {
        async function fetchMantenimiento() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorMantenimiento.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener mantenimiento",
                    id: props.id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setMantenimientos(Array.isArray(data) ? data : []);
        }

        fetchMantenimiento();
    }, [props.id, reloadView]);

    useEffect(() => {
        const fechaActual = new Date();
        const nombreMesActual = obtenerNombreMes(fechaActual.getMonth());
        setNombreMes(nombreMesActual);
    
        const actividadesDefault = obtenerActividadesDefault(props.nombre);
        const actividadesRealizadas = mantenimientos.filter(mantenimiento => {
            // Parsear la fecha almacenada en la base de datos
            const partesFecha = mantenimiento.fecha_mant.split('/');
            const fechaMant = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // Año, mes (restar 1 porque los meses en JavaScript son base 0), día
            const mesMant = fechaMant.getMonth();
            console.log(mesMant);
    
            return mesMant === fechaActual.getMonth();
        }).map(mantenimiento => mantenimiento.actividad);
    
        // Contar la cantidad de veces que una actividad aparece en la base de datos
        const actividadesConContador = actividadesDefault.map(({ actividad, maxActividades }) => {
            const contador = actividadesRealizadas.filter(act => act === actividad).length;
            return { actividad, contador, maxActividades };
        });
    
        setActividadesConContador(actividadesConContador);
    }, [props.nombre, mantenimientos, reloadView]);    

    useEffect(() => {
        const fechaActual = new Date();
        const nombreMesActual = obtenerNombreMes(fechaActual.getMonth());
        setNombreMes(nombreMesActual);
    })

    // Función para obtener las actividades predeterminadas según el nombre de la máquina
    function obtenerActividadesDefault(nombreMaquina) {
        let actividadesDef = [];
        let maxActividades = 0;
        switch (nombreMaquina) {
            case 'Compresor':
                actividadesDef = [
                    'Revisión de Presiones',
                    'Nivel de Aceites',
                    'Fugas con condensador y accesorios',
                    'Limpieza'
                ];
                maxActividades = 1;
                break;
            case 'Banda Alimentadora de Papas':
                actividadesDef = [
                    'Engrasar Chumaceras',
                    'Lubricar Rodillos',
                    'Checar nivel de aceite a transmisión',
                    'Revsion a sistemas mecanicos',
                    'Revision de cepillos y tubos pvc',
                ];
                maxActividades = 4;
                break;
            case 'Enjuague y Cepillos Papas':
                actividadesDef = [
                    'Engrasar Chumaceras',
                    'Lubricar Rodillos y cadenas',
                    'Checar nivel de aceite a transmision',
                    'Revision a sistemas mecanicos',
                    'Revision de cepillos',
                    'Limpieza a bombas, filtros y aspersores',
                ];
                maxActividades = 2;
                break;
            case 'Patines':
                actividadesDef = [
                    'Engrasar Chumaceras',
                    'Lubricar Rodillos',
                    'Limpieza',
                    'Checar nivel de aceite a transmision'
                ];
                maxActividades = 4;
                break;
            case 'Sistema Hidraulico':
                actividadesDef = [
                    'Revision de funcionamiento y fugas en lavamanos y tarjas',
                    'Revision de funcionamiento y fugas en wc',
                    'Revision de los diversos componentes hidraulicos, mecanicos y electricos de los tinacos',
                    'Revision de fugas y niveles de aires lavados',
                ];
                maxActividades = 1;
                break;
            case 'Alumbrado General':
                actividadesDef = [
                    'Limpieza gabinetes',
                    'Reparacion de luminarias en caso necesario',
                    'Revision de controles automaticos area ventas',
                ];
                maxActividades = 1;
                break;
            case 'Elevador':
                actividadesDef = [
                    'Lubricacion guias',
                    'Revision puertas y mecanismos',
                    'Revision cables acerado',
                    'Revision de electro freno',
                    'Revision novel aceite',
                ]
                maxActividades = 1;
                break;
            case 'Banda descarga de Papas':
            case 'Banda descarga de Plátanos':
            case 'Banda Rodillo Alumino':
            case 'Difusores':
                actividadesDef = [
                    'Engrasar Chumaceras',
                    'Lubricar Rodillos',
                    'Limpieza',
                    'Checar nivel de aceite a transmision'
                ];
                maxActividades = 2;
                break;
            case 'Carcamo Área de Papas':
            case 'Carcamo Área de Plátanos':
                actividadesDef = [
                    'Medir necesidad de encender bomba',
                    'Checar nivel',
                    'Desaguar fosa'
                ];
                maxActividades = 2;
                break;
            case 'Limpiadora de Cebolla':
                actividadesDef = [
                    'Engrasar Chumaceras',
                    'Lubricar Rodillos y Cadenas',
                    'Checar nivel de aceite a transmision',
                    'Revision a sistemas mecanicos',
                    'Revision de cepillos y tubos pvc'
                ];
                maxActividades = 2;
                break;
            default:
                actividadesDef = [];
                break;
        }
        return actividadesDef.map(actividad => ({ actividad, maxActividades }));
    }

    // Función para obtener el nombre del mes
    function obtenerNombreMes(numeroMes) {
        const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return meses[numeroMes];
    }

    // Función para recargar la vista
    function recargarVista() {
        setReloadView(prevState => !prevState);
    }
    const actividadesPorMes = actividadesConContador[0]?.maxActividades;
    return (
        <div className="card has-background-danger-dark has-text-centered" style={{ width: '100%', height: '100vh', overflow: 'scroll', scrollbarWidth: 'none' }}>
            <br />
            <h1 className="title has-text-white has-text-centered is-5 m-0 mb-1">Mes en curzo: {nombreMes}</h1>
            <p className="tag is-link m-0">Actividades por mes: {actividadesPorMes}</p>
            <button className="button btn-icon is-warning icon is-medium" style={{ position: 'absolute', top: '1px', right: '1px' }}  onClick={recargarVista}><i className="fas fa-redo-alt"></i></button>
            <List>
                {actividadesConContador.map((item, index) => (
                    // Agrega una condición para mostrar solo las actividades cuyo contador no ha alcanzado el máximo
                    item.contador < item.maxActividades && (
                        <ListItem key={index} className="message is-danger m-0 p-2" alignItems="flex-start">
                            <ListItemAvatar>
                                <Icon className="fa-regular fa-circle-xmark"></Icon>
                            </ListItemAvatar>
                            <ListItemText className="message-body m-0"
                                primary={item.actividad}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            style={{ display: 'inline', color: 'white', fontSize: '12.5px', width: '100%' }}
                                            component="span"
                                        >
                                            Avance: <b>{Math.round((item.contador / item.maxActividades) * 100)}%</b>
                                            <br />
                                            <progress style={{ width: '100%' }} className="progress is-success is-small" value={item.contador} max={item.maxActividades} />
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    )
                ))}
            </List>
        </div>
    );
}

export default ViewActividades;
