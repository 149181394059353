import React, { useState, useEffect } from "react";
import Servidor from "./Servidor";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AddMantenimiento from "./ViewMaquinaria/AddMantenimiento";
import ListaMantenimientos from "./ViewMaquinaria/ListaMantenimientos";
import MenuMaq from "./ViewMaquinaria/Menu/MenuMaq";
import { infoMaquinaria } from "../utils/helpers";
import ViewActividades from "./ViewMaquinaria/ViewActividades";
import EditWindow from "./EditWindow";
import ListaRefacMaq from "./ViewMaquinaria/ListaRefacMaq";

function ViewMaquinaria() {
    const rol = localStorage.getItem("rol");
    const token = localStorage.getItem("token");
    const { id } = useParams();
    const [maquinaria, setMaquinaria] = useState({
        id: "",
        nombre: "",
        num: "",
        descripcion: "",
    });
    const [selectedView, setSelectedView] = useState("");

    useEffect(() => {
        async function fetchMaquinaria() {
            try {
                if (id) {
                    const respuesta = await fetch(`${Servidor.url}/controller/ControladorMaquinaria.php`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            action: 'obtener maquinaria',
                            id: id
                        })
                    });
                    const maquinariaData = await respuesta.json();
                    setMaquinaria(maquinariaData);
                }
            } catch (error) {
                console.error("Error al obtener la maquinaria:", error);
            }
        }
        fetchMaquinaria();
    }, [id]);

    const handleSelectView = (path) => {
        setSelectedView(path);
    };

    let contentView = null;
    switch (selectedView) {
        case 'guardarMantenimiento':
            if (rol === 'Admin' || rol === 'sudo') {
                contentView = <AddMantenimiento id={maquinaria.id} />;
            } else {
                contentView = <h1 className="tag is-warning title is-4 has-text-black">No tienes permisos para agregar mantenimientos</h1>;
            }
            break;
        case 'listaMantenimientos':
            contentView = <ListaMantenimientos id={maquinaria.id} />;
            break;
        case 'listaRefacciones':
            contentView = <ListaRefacMaq id={maquinaria.id} />;
            break;
        default:
            contentView = <ListaMantenimientos id={maquinaria.id} />;
    }

    if (!token){
        return <div className="notification is-info"><h1 className="title">Necesitas iniciar sesión para poder entrar o tu usuario no tiene permisos para entrar aquí</h1></div>
    }
    return (
        <article style={{ width: '100%' }} className="media">
            <figure className="media-left" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '96.5vh', width: '18%' }}>
                <p className="image">
                    <img className="card" style={{ height: '35vh', width: '100%' }} src={maquinaria.foto_maq} alt="Foto de la maquinaria" />
                </p>
                <p className="card-content p-1">{maquinaria.descripcion}</p>
                <br />
                <p className="title is-6 has-text-centered">Actividades por hacer</p>
                <ViewActividades id={maquinaria.id} nombre={maquinaria.nombre} />
            </figure>
            <div className="media-content">
                <div className="card has-background-grey p-3" style={{ height: '100%' }}>
                    <p className="card-header-title" style={{ display: 'contents' }}>
                        <strong className="title is-3 m-3">{maquinaria.nombre}</strong> <small className="tag is-link">ID de la BD<b>: {maquinaria.id}</b></small>
                        <br />
                        <small className="title is-6 m-3">Número de maquinaria: {maquinaria.num}</small><br />
                    </p>
                    <div className="p-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <MenuMaq onSelectView={handleSelectView} />
                        {contentView}
                    </div>
                </div>
            </div>
            {rol === 'Admin' || rol === 'sudo' ? <div className="button btn-icon is-warning is-outlined" id="edit">{maquinaria.id && <EditWindow id={maquinaria.id} />}</div> : null}
            <button id="datos" onClick={() => infoMaquinaria()} className="button btn-icon is-warning is-inverted is-outlined mt-2"><i className="fas fa-question fa-flip"></i></button>
        </article>
    );
}

export default ViewMaquinaria;
