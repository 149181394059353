import React from 'react';
import ItemsMenuMaq from './ItemsMenuMaq';

function MenuMaq({ onSelectView }) {
  return (
    <div style={{ width: '100%' }} className="mb-3">
      <div class="tabs is-centered" style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
        <ul>
          {ItemsMenuMaq.map((item) => (
            <li key={item.title}>
              <a onClick={() => onSelectView(item.path)}>
                {item.Icon && <item.Icon />} {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MenuMaq;
