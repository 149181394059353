import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Servidor from "../components/Servidor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BarraPage from './BarraPage';
import ItemsCapturaInv from '../components/ItemsCapturaInv';
import { infoListaRefac } from '../utils/helpers';
import { Icon } from '@material-ui/core';

function CapturaInv() {
    const token = localStorage.getItem("token");
    const [refacciones, setRefacciones] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        async function fetchRefacciones() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacciones.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setRefacciones(data);
        }
        fetchRefacciones();
    }, []);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const handleChange = (e, id, field) => {
        const newValue = e.target.value;
        setRefacciones((prevState) =>
            prevState.map((refaccion) =>
                refaccion.id === id ? { ...refaccion, [field]: newValue } : refaccion
            )
        );
    };

    const manejarEnvioDatos = async () => {
        try {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorDiferencia.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "guardar",
                    datos: refacciones,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const exitoso = await respuesta.json();
            if (exitoso === true) {
                toast('Inventario Guardado 🧰', {
                    position: "top-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("Error: Posiblemente ya capturaste Inventario el día de hoy 🤷‍♀️");
            }
        } catch (error) {
            toast.error("Error de red al enviar los datos");
        }
    };

    if (!token) {
        return <div className="notification is-info"><h1 className="title">Necesitas iniciar sesión para poder entrar o tu usuario no tiene permisos para entrar aquí</h1></div>;
    }

    return (
        <div className="card has-background-grey p-4" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            <button id="datos" onClick={infoListaRefac} className="button btn-icon is-primary is-inverted is-outlined"><i className="fas fa-question fa-flip"></i></button>
            <br />
            <div id='maquinas' className="card" style={{ margin: 0 }}>
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th style={{ width: "9%" }}><i className="icon is-medium fas fa-arrow-down-1-9"></i> ID</th>
                            <th><i className="icon is-medium fas fa-toolbox"></i>Nombre</th>
                            <th style={{ width: "19%" }}><i className="icon is-medium fas fa-hashtag"></i>Unidades de Sistema</th>
                            <th style={{ width: "17%" }}><i className="icon is-medium fas fa-hashtag"></i>Unidades Físicas</th>
                            <th style={{ width: "14%" }}><i className="icon is-medium fas fa-calendar-alt"></i>Fecha Captura</th>
                            <th style={{ width: "15%" }}><i className="icon is-medium fas fa-comment"></i> Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {refacciones
                            .filter((refaccion) => 
                                refaccion.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.nom_refac.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.fecha_capt.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.cant.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.costo_refac.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((refaccion) => (
                                <ItemsCapturaInv key={refaccion.id} refaccion={refaccion} onChange={handleChange} />
                            ))}
                    </tbody>
                </table>
                <button onClick={manejarEnvioDatos} style={{ left: "5rem", bottom: "2rem", width: "16rem" }} className="button datos btn-icon is-warning is-inverted is-outlined"><i class="fa-solid fa-cloud-arrow-up mr-2"></i>Guardar Inventario</button>
                <Link to="/listarefacciones" style={{ right: "3rem", top: "2rem", width: "8rem", fontSize: "1rem" }} className="button datos is-danger is-dark"><Icon className="fas fa-xmark mr-2"></Icon> Cancelar</Link>
            </div>
        </div>
    );
}

export default CapturaInv;
