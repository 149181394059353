import Swal from "sweetalert2";
export function infoListaMaquinaria() {
    Swal.fire({
      title: 'Funciones',
      html: `
            <b style="display: flex; flex-direction: column; align-items: center;">
                <div class="button btn-icon is-warning"><i class="fa fa-edit"></i></div> Editar Infromación de la Maquinaria
                <div class="button btn-icon is-danger"><i class="fa fa-trash"></i></div> Eliminar Maquinaria <br><br>
                También puedes acceder a la información de la maquinaria pulsando sobre el nombre, para poder ver las actividades de mantenimiento que se han realizado.
            </b>
            `,
      icon: 'question',
      confirmButtonText: 'Cerrar',
    });
}
export function infoListaMantenimiento() {
    Swal.fire({
      title: 'Funciones',
      html: `
            <b style="display: flex; flex-direction: column; align-items: center;">
                <div class="button btn-icon is-danger"><i class="fa fa-trash"></i></div> Eliminar Mantenimiento <br><br>
                <div class="button btn-icon is-success is-inverted is-outlined"><i class="fa fa-download"></i></div> Descargar Mantenimientos ingresando fechas deseadas <br><br>
                También puedes acceder a la información de la maquinaria pulsando sobre el nombre, para poder ver las actividades de mantenimiento que se han realizado.
            </b>
            `,
      icon: 'question',
      confirmButtonText: 'Cerrar',
    });
}
export function infoListaRefac() {
  Swal.fire({
    title: 'Funciones',
    html: `
          <b style="display: flex; flex-direction: column; align-items: center;">
              <div class="button btn-icon is-danger"><i class="fa fa-trash"></i></div> Eliminar La Refacción <br><br>
              <div class="button btn-icon is-info"><i class="fa fa-square-plus"></i></div> Agregar piezas al inventario <br><br>
              <div class="button btn-icon is-link"><i class="fa fa-trowel-bricks"></i></div> Consumir la Refacción asignando la cantidad de piezas a usar y seleccionando la maquinaria en la que se utilizará
          </b>
          `,
    icon: 'question',
    confirmButtonText: 'Cerrar',
  });
}
export function infoListaRefacUse() {
  Swal.fire({
    title: 'Funciones',
    html: `
          <b style="display: flex; flex-direction: column; align-items: center;">
            En esta lista sólo podrás ver la información de las refacciones que ya fueron utilizadas para las diferentes maquinas registradas. <br><br>
            También puedes acceder a la información de la maquinaria pulsando sobre el nombre, para poder ver las actividades de mantenimiento que se han realizado junto a las refacciones utilizadas.
          </b>
          `,
    icon: 'question',
    confirmButtonText: 'Cerrar',
  });
}
export function infoMaquinaria() {
    Swal.fire({
      title: 'Funciones',
      html: `
            <b style="display: flex; flex-direction: column; align-items: center;">
                En esta sección podrás ver la información completa de la maquina junto a la foto que decidiste guardar para identificarla.<br><br>
                Tambien poder añadir nuevas actividades de mantenimiento en la sección de "Registrar Mantenimiento", y poder verlas enlistadas en la sección de "Ver Mantenimientos Realizados", con los costos que éstas generaron.<br><br>
                En la parte inferior izquierda podrás ver las actividades por realizar y la cantidad de veces planeadas por mes. <br><br>
                Se añadió una lista de refacciones utilizadas para la maquinaria, donde puedes verlas y eliminarlas si es que hubo un fallo en alguna. <br><br>                
                Ademas de poseer el boton de editar la maquina con el botón de editar que está justo arruba de éste.
            </b>
            `,
      icon: 'question',
      width: 800,
      confirmButtonText: 'Cerrar',
    });
}
export function infoVersion() {
  Swal.fire({
    title: '¿Qué es lo nuevo?',
    html: `
          <b style="display: flex; flex-direction: column; align-items: center;">
            <li>Se añadió la posibilidad de una toma de inventario para las refacciones, para hacer una comparativa en el inventario por sistema y el físico.</li>
            <br>
            <li>Se habilitó la descarga de los inventarios por día en formato Excel, para poder hacer un seguimiento de los inventarios.</li>
          </b>
          `,
    icon: 'question',
    confirmButtonText: 'Cerrar',
  });
}