import React, { useState, useEffect } from 'react';
import Servidor from "../components/Servidor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemsMaquinaria from "../components/ItemsMaquinaria";
import BarraPage from './BarraPage';
import { infoListaMaquinaria } from '../utils/helpers';
import RegisterWindow from '../components/RegisterWindow';

function ListaMaquinaria() {
    const token = localStorage.getItem("token");
    const [maquinarias, setMaquinarias] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const rol = localStorage.getItem('rol');

    useEffect(() => {
        async function fetchMaquinaria() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorMaquinaria.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setMaquinarias(data);
        }
        fetchMaquinaria();
    }, []);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    if (!token) {
        return <div className="notification is-info"><h1 className="title">Necesitas iniciar sesión para poder entrar o tu usuario no tiene permisos para entrar aquí</h1></div>;
    }
    let agregar = (rol === 'Admin' || rol === 'sudo') ? <RegisterWindow /> : null

    return (
        <div className="card has-background-grey p-4" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            {agregar}
            <button id="datos" onClick={() => infoListaMaquinaria()} className="button btn-icon is-warning is-inverted is-outlined"><i className="fas fa-question fa-flip"></i></button>
            <br />
            <div id='maquinas' className="card has-background-dark">
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th style={{ width: "6%" }}><i className="icon is-medium fas fa-arrow-down-1-9"></i> ID</th>
                            <th><i className="icon is-medium fas fa-cogs"></i>Nombre</th>
                            <th style={{ width: "8%" }}><i className="icon is-medium fas fa-hashtag"></i>Número</th>
                            <th><i className="icon is-medium fas fa-align-justify"></i>Descripción</th>
                            <th style={{ width: "15%" }}><i className="icon is-medium fas fa-gear"></i> Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maquinarias
                            .filter((maquinaria) => 
                                maquinaria.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                maquinaria.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                maquinaria.num.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                maquinaria.descripcion.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((maquinaria) => (
                                <ItemsMaquinaria key={maquinaria.id} maquinaria={maquinaria} />
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListaMaquinaria;
