import Swal from "sweetalert2";
import { Icon } from "@material-ui/core";

const menuItems = (isLoggedIn) => {
    const rol = localStorage.getItem('rol');
    const Inicio = {
        title: 'Inicio',
        path: '',
        Icon: () => <Icon className="fa fa-house icon is-medium tag is-info btn-icon"></Icon>
    }
    const listaMaquinaria = {
        title: 'Lista Maquinaria',
        path: 'listamaquinaria',
        Icon: () => <Icon className="fas fa-cogs icon is-medium tag is-primary btn-icon"></Icon>
    }
    const listaMantenimientos = {
        title: 'Lista Mantenimientos',
        path: 'listamantenimientosall',
        Icon: () => <Icon className="fas fa-tools icon is-medium tag btn-icon is-danger"></Icon>
    }
    const listaRefacciones = {
        title: 'Lista Refacciones',
        path: 'listarefacciones',
        Icon: () => <Icon className="fas fa-toolbox icon is-medium tag btn-icon is-warning"></Icon>
    }
    const listaRefaccionesUse = {
        title: 'Lista Refacciones Usadas',
        path: 'listarefaccionesuse',
        Icon: () => <Icon className="fas fa-trowel-bricks icon is-medium tag btn-icon is-link"></Icon>
    }

    const items = [Inicio];
    if (rol === 'sudo') {
        items.push( listaMaquinaria, listaMantenimientos, listaRefacciones, listaRefaccionesUse );
    } else if (rol === 'Admin') {
        items.push( listaMaquinaria, listaMantenimientos, listaRefacciones, listaRefaccionesUse );
    } else if (rol === 'Visita') {
        items.push(listaMaquinaria, listaMantenimientos, listaRefacciones, listaRefaccionesUse );
    }

    // Agregar elementos adicionales según sea necesario
    if (isLoggedIn) {
        items.push({
            title: 'Cerrar Sesión',
            onClick: async () => {
                const respuesta = await Swal.fire({
                    title: '¿Seguro que quieres cerrar la sesión?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, cerrar',
                    cancelButtonText: 'Cancelar'
                });
                if (respuesta.isConfirmed) {
                    localStorage.clear();
                    window.location.href = '/';
                }
            },
            Icon: () => <Icon className="fas fa-sign-out-alt mr-2"></Icon>
        });
        
    }

    return items;
};

export default menuItems;
