import React, { useState, useEffect } from 'react';
import Servidor from "../components/Servidor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemsMantAll from '../components/ItemsMantAll';
import BarraPage from './BarraPage';
import { infoListaMantenimiento } from '../utils/helpers';

function ListaMantenimientosAll() {
    const token = localStorage.getItem("token");
    const [mantenimientos, setMantenimientos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalGastos, setTotalGastos] = useState(0);

    useEffect(() => {
        async function fetchMantenimiento() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorMantenimiento.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setMantenimientos(Array.isArray(data) ? data : []);
        }
        fetchMantenimiento();
    }, []); // Agregar props.id como dependencia del efecto useEffect

    useEffect(() => {
        // Calcular la suma de los gastos del mantenimiento
        const total = mantenimientos.reduce((accumulator, current) => accumulator + parseFloat(current.costo), 0);
        setTotalGastos(total);
    }, [mantenimientos]);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        // Calcular la suma de los gastos del mantenimiento cada vez que cambia el término de búsqueda o la lista de mantenimientos
        const total = mantenimientos
            .filter((mantenimiento) =>
                mantenimiento.nombre_maquina.toLowerCase().includes(searchTerm.toLowerCase()) ||
                mantenimiento.actividad.toLowerCase().includes(searchTerm.toLowerCase()) ||
                mantenimiento.fecha_mant.toLowerCase().includes(searchTerm.toLowerCase()) ||
                mantenimiento.costo.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .reduce((accumulator, current) => accumulator + parseFloat(current.costo), 0);
        setTotalGastos(total);
    }, [searchTerm, mantenimientos]);

    if (!token) {
        return <div className="notification is-info"><h1 className="title">Necesitas iniciar sesión para poder entrar o tu usuario no tiene permisos para entrar aquí</h1></div>;
    }

    return (
        <div className="card has-background-grey p-4" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            <button id="datos" onClick={() => infoListaMantenimiento()} className="button btn-icon is-warning is-outlined is-inverted"><i className="fas fa-question fa-flip"></i></button>
            <br />
            <div id='mantenimientosall' className="card mb-1" style={{ margin: 0 }}>
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th><i className="fas fa-cogs icon is-medium"></i> Nombre de la máquina</th>
                            <th><i className="fas fa-tools icon is-medium"></i> Actividad</th>
                            <th><i className="icon is-medium fas fa-calendar-day"></i> Fecha</th>
                            <th><i className="icon is-medium fas fa-dollar-sign"></i> Costo</th>
                            <th><i className="icon is-medium fas fa-gear"></i> Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mantenimientos
                            .filter((mantenimiento) => 
                                mantenimiento.nombre_maquina.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                mantenimiento.actividad.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                mantenimiento.fecha_mant.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                mantenimiento.costo.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((mantenimiento) => (
                                <ItemsMantAll key={mantenimiento.id} mantenimiento={mantenimiento} />
                            ))}
                    </tbody>
                </table>
            </div>
            <h1 className='tag has-text-dark is-warning title is-5' style={{ margin: 0, width: "100%"}}> 
                Total de Gastos: ${totalGastos}
            </h1>
        </div>
    );
}

export default ListaMantenimientosAll;
