import React from 'react';
import { Menu, MenuItem, Button, Icon } from '@material-ui/core';
import DescargarInv from '../components/downloads/DescargarInv';
import DescargarRefac from '../components/downloads/DescargarRefac';
import DescargarMants from '../components/downloads/DescargarMants';
import DescargarRefacUse from '../components/downloads/DescargarRefacUse';
export default function MenuDesc() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='has-background-grey'>
      <Button style={{ color: 'greenyellow', border: '1px solid greenyellow', width: '100%', textAlign: 'center' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Icon className="fas fa-download mr-2"></Icon> Menu de Descargas
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem><DescargarMants /></MenuItem>
        <MenuItem><DescargarRefac /></MenuItem>
        <MenuItem><DescargarRefacUse /></MenuItem>
        <MenuItem><DescargarInv /></MenuItem>
      </Menu>
    </div>
  );
}
