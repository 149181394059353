import React, { useState, useEffect } from 'react';
import Servidor from "../Servidor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemsMantenimiento from './ItemsMantenimiento';
import BarraPage from '../../pages/BarraPage';

function ListaMantenimientos(props) {
    const [mantenimientos, setMantenimientos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalGastos, setTotalGastos] = useState(0);

    useEffect(() => {
        async function fetchMantenimiento() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorMantenimiento.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener mantenimiento",
                    id: props.id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setMantenimientos(Array.isArray(data) ? data : []);
        }
        fetchMantenimiento();
    }, [props.id]); // Agregar props.id como dependencia del efecto useEffect

    useEffect(() => {
        // Calcular la suma de los gastos del mantenimiento
        const total = mantenimientos.reduce((accumulator, current) => accumulator + parseFloat(current.costo), 0);
        setTotalGastos(total);
    }, [mantenimientos]);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        // Calcular la suma de los gastos del mantenimiento cada vez que cambia el término de búsqueda o la lista de mantenimientos
        const total = mantenimientos
            .filter((mantenimiento) =>
                mantenimiento.actividad.toLowerCase().includes(searchTerm.toLowerCase()) ||
                mantenimiento.fecha_mant.toLowerCase().includes(searchTerm.toLowerCase()) ||
                mantenimiento.costo.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .reduce((accumulator, current) => accumulator + parseFloat(current.costo), 0);
        setTotalGastos(total);
    }, [searchTerm, mantenimientos]);

    return (
        <div className="card has-background-dark p-3" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            <div id='mantenimientos' className="card mb-1 mt-3" style={{ margin: 0 }}>
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th><i className="fas fa-tools icon is-medium"></i>Actividad</th>
                            <th><i className="fas fa-calendar-day icon is-medium"></i> Fecha</th>
                            <th><i className="fas fa-dollar-sign icon is-medium"></i>Costo</th>
                            <th><i className="fas fa-comment icon is-medium"></i>Comentario</th>
                            <th style={{ width: "15%" }}><i className="fas fa-gear icon is-medium"></i>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mantenimientos
                            .filter((mantenimiento) => 
                                mantenimiento.actividad.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                mantenimiento.fecha_mant.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                mantenimiento.costo.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((mantenimiento) => (
                                <ItemsMantenimiento key={mantenimiento.id} mantenimiento={mantenimiento} />
                            ))}
                    </tbody>
                </table>
            </div>
            <h1 className='tag has-text-dark is-warning title is-5' style={{ margin: 0, width: "100%"}}> 
                Total de Gastos: ${totalGastos} 
            </h1>
        </div>
    );
}

export default ListaMantenimientos;
