import React, { useState, useEffect } from 'react';
import Servidor from "../components/Servidor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BarraPage from './BarraPage';
import ItemsRefacUse from '../components/ItemsRefacUse';
import { infoListaRefacUse } from '../utils/helpers';
function ListaRefacUse() {
    const token = localStorage.getItem("token");
    const [refacciones, setRefacciones] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalGastos, setTotalGastos] = useState(0);

    useEffect(() => {
        async function fetchRefacciones() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacUsada.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "obtener",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setRefacciones(Array.isArray(data) ? data : []);
        }
        fetchRefacciones();
    }, []);

    useEffect(() => {
        const total = refacciones.reduce((accumulator, current) => 
            accumulator + (parseFloat(current.costo_use) || 0) * (parseFloat(current.cant_use) || 0), 
            0
        );
        setTotalGastos(total);
    }, [refacciones]);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        const total = refacciones
            .filter((refaccion) => {
                return (
                    refaccion.nombre_maquina.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    refaccion.nombre_refaccion.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    refaccion.num_maquina.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                    refaccion.fecha_use.includes(searchTerm) ||
                    refaccion.cant_use.includes(searchTerm) ||
                    refaccion.costo_use.includes(searchTerm)
                );
            })
            .reduce((accumulator, current) => 
                accumulator + (parseFloat(current.costo_use) || 0) * (parseFloat(current.cant_use) || 0), 
                0
            );
        setTotalGastos(total);
    }, [searchTerm, refacciones]);

    if (!token) {
        return <div className="notification is-info"><h1 className="title">Necesitas iniciar sesión para poder entrar o tu usuario no tiene permisos para entrar aquí</h1></div>;
    }

    return (
        <div className="card has-background-grey p-4" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            <button id="datos" onClick={infoListaRefacUse} className="button btn-icon is-success is-inverted is-outlined"><i className="fas fa-question fa-flip"></i></button>
            <br />
            <div id='mantenimientosall' className="card mb-1" style={{ margin: 0 }}>
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th><i className="icon is-medium fas fa-toolbox"></i>Refacción</th>
                            <th><i className="icon is-medium fas fa-cogs"></i>Maquina</th>
                            <th><i className="icon is-medium fas fa-hashtag"></i>Cantidad Usada</th>
                            <th style={{ width: "12%" }}><i className="icon is-medium fas fa-dollar-sign"></i>Costo</th>
                            <th style={{ width: "20%" }}><i className="icon is-medium fas fa-calendar-alt"></i>Fecha de Uso</th>
                        </tr>
                    </thead>
                    <tbody>
                        {refacciones
                            .filter((refaccion) => 
                                refaccion.nombre_maquina.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.nombre_refaccion.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.num_maquina.toLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                                refaccion.fecha_use.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.cant_use.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                refaccion.costo_use.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((refaccion) => (
                                <ItemsRefacUse key={refaccion.id} refaccion={refaccion} />
                            ))}
                    </tbody>
                </table>
            </div>
            <h1 className='tag has-text-dark is-warning title is-5' style={{ margin: 0, width: "100%" }}> 
                Total de Gastos: ${totalGastos.toFixed(2)}
            </h1>
        </div>
    );
}

export default ListaRefacUse;