// BarraPage.jsx
import React from 'react';
import SearchBar from '../components/searchBar';
function BarraPage({ onSearch }) {
    const handleChange = (event) => {
        const searchTerm = event.target.value;
        onSearch(searchTerm);
    };

    return (
        <div className="barra">
            <SearchBar onChange={handleChange} />
        </div>
    );
}

export default BarraPage;
