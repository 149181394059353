import React from 'react';
import getMenuItems from '../components/menuItems';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, Icon } from '@material-ui/core';
import UserModal from './login/UserModal';
import MenuDesc from './MenuDesc';
const MiniMenu = ({isLoggedIn}) => {
    const items = getMenuItems(isLoggedIn);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const rol = localStorage.getItem('rol');
    let CrearUsuario = (rol === 'sudo') ? <UserModal /> : null
    const MenuDescargas = (!isLoggedIn) ? null : <MenuDesc />

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="button btn-icon is-success" id="minimenu">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Icon className="fas fa-bars"></Icon>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map((item) => (
                    <MenuItem id="menuitem" style={{ padding: '0' }} key={item.title} onClick={handleClose}>
                        {item.onClick ? (
                            <button className='button is-danger' style={{ width: '100%' }} onClick={item.onClick}>
                                <item.Icon /> {item.title}
                            </button>
                        ) : (
                            <Link style={{ color: 'white', margin: '3%', width: '100%' }} to={item.path}>
                                <item.Icon /> {item.title}
                            </Link>
                        )}
                    </MenuItem>
                ))}
                {MenuDescargas}
                {CrearUsuario}
            </Menu>
        </div>
    );
}

export default MiniMenu;