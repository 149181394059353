import React from "react";
import { FormControl, OutlinedInput } from "@material-ui/core";

class ItemsCapturaInv extends React.Component {
    manejarCambio = (e, field) => {
        this.props.onChange(e, this.props.refaccion.id, field);
    }

    render() {
        const { refaccion } = this.props;
        refaccion.fecha_captura = new Date().toISOString().slice(0, 10);

        return (
            <tr>
                <td>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            style={{ color: 'white' }}
                            value={refaccion.id}
                            readOnly
                            name="refaccion_id"
                        />
                    </FormControl>
                </td>
                <td>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            style={{ color: 'white' }}
                            value={refaccion.nom_refac}
                            readOnly
                        />
                    </FormControl>
                </td>
                <td>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            style={{ color: 'white' }}
                            value={refaccion.cant}
                            readOnly
                            name="cant_sistema"
                        />
                    </FormControl>
                </td>
                <td>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            style={{ color: 'white' }}
                            type="number"
                            value={refaccion.cantFisica || ''}
                            onChange={(e) => this.manejarCambio(e, 'cantFisica')}
                            name="cant_fisica"
                        />
                    </FormControl>
                </td>
                <td>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            style={{ color: 'white' }}
                            type="date"
                            value={refaccion.fecha_captura || ''}
                            onChange={(e) => this.manejarCambio(e, 'fecha_captura')}
                            name="fecha_captura"
                            readOnly
                        />
                    </FormControl>
                </td>
                <td>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            style={{ color: 'white' }}
                            type="text"
                            value={refaccion.observaciones || ''} 
                            onChange={(e) => this.manejarCambio(e, 'observaciones')}
                        />
                    </FormControl>
                </td>
            </tr>
        );
    }
}

export default ItemsCapturaInv;