import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './pages/menu';
import Inicio from './pages/Inicio';
import ListaMaquinaria from './pages/ListaMaquinaria';
import MaquinariaPage from './pages/MaquinariaPage';
import ListaMantenimientosAll from './pages/ListaMantenimientosAll';
import ListaRefac from './pages/ListaRefac';
import Swal from 'sweetalert2';
import MiniMenu from './pages/MiniMenu';
import ListaRefacUse from './pages/ListaRefacUse';
import CapturaInv from './pages/CapturaInv';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Verificar si hay un token de sesión presente al cargar la aplicación
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }

    // Establecer temporizador para cerrar la sesión después de cierto tiempo de inactividad
    let inactivityTimeout = setTimeout(() => {
      if (isLoggedIn) {
        cerrarSesion();
      }
    }, 1800000);

    // Reiniciar temporizador cuando hay actividad del mouse
    const reiniciarTemporizador = () => {
      clearTimeout(inactivityTimeout);
      if (isLoggedIn) {
        inactivityTimeout = setTimeout(() => {
          cerrarSesion();
        }, 1800000);
      }
    };

    // Agregar el event listener para el movimiento del mouse
    document.addEventListener('mousemove', reiniciarTemporizador);

    // Remover el event listener cuando el componente se desmonte
    return () => {
      document.removeEventListener('mousemove', reiniciarTemporizador);
    };
  }, [isLoggedIn]);

  const cerrarSesion = () => {
    // Limpiar el localStorage y redirigir a la página de inicio de sesión
    localStorage.clear();
    setIsLoggedIn(false);
    // Mostrar el mensaje de cierre de sesión con SweetAlert
    Swal.fire({
      title: '¡Sesión cerrada!',
      text: 'Tu sesión ha sido cerrada debido a inactividad.',
      icon: 'info',
      confirmButtonText: 'OK'
    });
    // Redirigir a la página de inicio de sesión u otra página según sea necesario
    window.location.href = '';
  };

  return (
    <Router>
      <Menu isLoggedIn={isLoggedIn} />
      <MiniMenu isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="listamaquinaria" element={<ListaMaquinaria />} />
        <Route path="" element={<Inicio isLoggedIn={isLoggedIn} />} />
        <Route path="ver-maquinaria/:id" element={<MaquinariaPage />} />
        <Route path="listamantenimientosall" element={<ListaMantenimientosAll />} />
        <Route path="listarefacciones" element={<ListaRefac />} />
        <Route path="listarefaccionesuse" element={<ListaRefacUse />} />
        <Route path="capturainv" element={<CapturaInv />} />
      </Routes>
    </Router>
  );
}

export default App;