import React from "react";
import { Link } from "react-router-dom";

class ItemsRefacUse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eliminado: false
        };
    }

    render() {
        if (this.state.eliminado) {
            return null;
        }
        let sumaCosto = parseFloat(this.props.refaccion.costo_use) * parseFloat(this.props.refaccion.cant_use);
        return (
            <tr>
                <td>{this.props.refaccion.nombre_refaccion}</td>
                <td><Link className="has-text-info" to={`/ver-maquinaria/${this.props.refaccion.id_maqu}`}>{this.props.refaccion.nombre_maquina} | Número: <b className="tag is-danger">{this.props.refaccion.num_maquina}</b></Link></td>
                <td>{this.props.refaccion.cant_use}</td>
                <td><p className="tag is-success is-medium has-text-weight-bold"><i className="fas fa-dollar-sign icon"></i> {sumaCosto}</p></td>
                <td>{this.props.refaccion.fecha_use}</td>
            </tr>
        );
    }
}

export default ItemsRefacUse;