import React, { Component } from 'react';
import Calendar from 'react-calendar'; // Importa la biblioteca de calendario que elijas
import 'react-calendar/dist/Calendar.css';
import Servidor from '../../components/Servidor';
import { ToastContainer, toast } from 'react-toastify';
import { FormControl, InputLabel, Input, Button, Icon, Checkbox, FormControlLabel } from '@material-ui/core'; // Importa componentes de Material-UI

class AddMantenimiento extends Component {
  constructor(props) {  
    super(props);
    this.state = {
      mantenimiento: {
        id_maq: this.props.id,
        fecha_mant: new Date().toISOString().split('T')[0], // Fecha actual en formato YYYY-MM-DD
        costo: '',
        actividades: [],
        coment: '',
        action: 'guardar',
      },
      maquinaria: null,
      actividadesDisponibles: [],
      mostrarInputOtraActividad: false,
      otraActividad: '',
      costosActividades: {},
    };
    this.cambioFecha = this.cambioFecha.bind(this);
    this.manejarCambio = this.manejarCambio.bind(this);
    this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
    this.toggleInputOtraActividad = this.toggleInputOtraActividad.bind(this);
    this.manejarCheckboxCambio = this.manejarCheckboxCambio.bind(this);
    this.manejarOtraActividadCambio = this.manejarOtraActividadCambio.bind(this);
    this.manejarCambioCostoActividad = this.manejarCambioCostoActividad.bind(this);
  }

  async componentDidMount() {
    try{
      const id = this.props.id; // Obtener el id de las props
      const respuesta = await fetch(`${Servidor.url}/controller/ControladorMaquinaria.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'obtener maquinaria',
          id: id
        }),
      });
      const maquinaria = await respuesta.json();
      this.setState({ maquinaria });
      let actividadesDisponibles = [];
      console.log(maquinaria.nombre);
      switch(maquinaria?.nombre){
        case 'Compresor':
          actividadesDisponibles = [
            'Revisión de Presiones',
            'Nivel de Aceites',
            'Fugas con condensador y accesorios',
            'Limpieza',
          ];
          break;
        case 'Banda Alimentadora de Papas':
          actividadesDisponibles = [
            'Engrasar Chumaceras',
            'Lubricar Rodillos',
            'Checar nivel de aceite a transmisión',
            'Revsion a sistemas mecanicos',
            'Revision de cepillos y tubos pvc',
          ];
          break;
        case 'Enjuague y Cepillos Papas':
          actividadesDisponibles = [
            'Engrasar Chumaceras',
            'Lubricar Rodillos y cadenas',
            'Checar nivel de aceite a transmision',
            'Revision a sistemas mecanicos',
            'Revision de cepillos y tubos pvc',
          ];
          break;
        case 'Patines':
          actividadesDisponibles = [
            'Engrasar Chumaceras',
            'Lubricar Rodillos',
            'Limpieza',
            'Checar nivel de aceite a transmision',
          ];
          break;
        case 'Sistema Hidraulico':
          actividadesDisponibles = [
            'Revision de funcionamiento y fugas en lavamanos y tarjas',
            'Revision de funcionamiento y fugas en wc',
            'Revision de los diversos componentes hidraulicos, mecanicos y electricos de los tinacos',
            'Revision de fugas y niveles de aires lavados',
          ];
          break;
        case 'Alumbrado General':
          actividadesDisponibles = [
            'Limpieza gabinetes',
            'Reparacion de luminarias en caso necesario',
            'Revision de controles automaticos area ventas',
          ];
          break;
        case 'Elevador':
          actividadesDisponibles = [
            'Lubricacion guias',
            'Revision puertas y mecanismos',
            'Revision cables acerado',
            'Revision de electro freno',
            'Revision novel aceite',
          ];
          break;
        case 'Banda descarga de Papas':
        case 'Banda descarga de Plátanos':
        case 'Banda Rodillo Aluminio':
        case 'Difusores':
          actividadesDisponibles = [
            'Engrasar Chumaceras',
            'Lubricar Rodillos',
            'Limpieza',
            'Checar nivel de aceite a transmision',
            ];
            break;
        case 'Carcamo Área de Papas':
        case 'Carcamo Área de Plátanos':
          actividadesDisponibles = [
            'Medir necesidad de encender bomba',
            'Checar nivel',
            'Desfugar fosa',
          ];
          break;
        case 'Limpiadora de Cebolla':
          actividadesDisponibles = [
            'Engrasar Chumaceras',
            'Lubricar Rodillos y Cadenas',
            'Checar nivel de aceite a transmision',
            'Revision a sistemas mecanicos',
            'Revision de cepillos y tubos pvc',
          ];
          break;
        default:
          actividadesDisponibles = [];
          break;
      }
      this.setState({ actividadesDisponibles });
    } catch (error) {
      console.error("Error al obtener la maquinaria:", error);
    }
  }

  cambioFecha(newDate) {
    // Convierte la fecha a un objeto Date válido
    const dateObject = new Date(newDate);
    // Obtiene el día, mes y año de la fecha
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Los meses se indexan desde 0
    const year = dateObject.getFullYear();
    // Formatea la fecha en el formato dd/mm/YYYY
    const formattedDate = `${day}/${month}/${year}`;
    // Actualiza el estado con la fecha formateada
    this.setState(prevState => ({
      mantenimiento: {
        ...prevState.mantenimiento,
        fecha_mant: formattedDate,
      }
    }));
  }

  manejarCambio(event) {
    const clave = event.target.id;
    let valor = event.target.value;
    this.setState(state => {
      const mantenimientoActualizado = state.mantenimiento;
      mantenimientoActualizado[clave] = valor;
      return { mantenimiento: mantenimientoActualizado };
    });
  }

  toggleInputOtraActividad() {
    this.setState(prevState => ({
      mostrarInputOtraActividad: !prevState.mostrarInputOtraActividad,
    }));
  }

  manejarCheckboxCambio(event) {
    const { value, checked } = event.target;
    this.setState(prevState => {
      const actividades = checked
        ? [...prevState.mantenimiento.actividades, value]
        : prevState.mantenimiento.actividades.filter(actividad => actividad !== value);

      const costosActividades = { ...prevState.costosActividades };
      if (checked) {
        costosActividades[value] = parseFloat(prevState.mantenimiento.costo) / actividades.length;
      } else {
        delete costosActividades[value];
      }
      return {
        mantenimiento: {
          ...prevState.mantenimiento,
          actividades,
        },
        costosActividades,
      };
    });
  }

  manejarCambioCostoActividad(event, actividad) {
    const { value } = event.target;
    this.setState(prevState => ({
      costosActividades: {
        ...prevState.costosActividades,
        [actividad]: value,
      },
    }));
  }

  manejarOtraActividadCambio(event) {
    this.setState({otraActividad: event.target.value});
  }

  validarFecha(fecha) {
    const regexFecha = /^\d{2}\/\d{2}\/\d{4}$/;
    return regexFecha.test(fecha);
  }

  async manejarEnvioDeFormulario(event) {
    event.preventDefault();
    const { mantenimiento, otraActividad, mostrarInputOtraActividad, costosActividades } = this.state;

    const actividades = mostrarInputOtraActividad && otraActividad.trim() !== '' 
      ? [...mantenimiento.actividades, otraActividad]
      : mantenimiento.actividades;

    const promesas = actividades.map(actividad => {
      // Aquí se asegura que si la actividad es "otra actividad", se tome el costo general
      const costo = actividad === otraActividad 
        ? parseFloat(mantenimiento.costo) 
        : (costosActividades[actividad] || 0);
        
      const mantenimientoConActividad = { 
        ...mantenimiento, 
        actividad, 
        costo 
      };

      return fetch(`${Servidor.url}/controller/ControladorMantenimiento.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mantenimientoConActividad),
      }).then(res => res.json());
    });

    try {
      const resultados = await Promise.all(promesas);
      const todosExitosos = resultados.every(resultado => resultado !== null);

      if (todosExitosos) {
        toast('Mantenimiento Guardado 🛠️', {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          mantenimiento: {
            id_maq: this.props.id,
            action: 'guardar',
            fecha_mant: new Date().toISOString().split('T')[0], // Fecha actual en formato YYYY-MM-DD
            costo: '',
            actividades: [],
            coment: '',
          },
          otraActividad: '',
        });
      } else {
        toast.error('Error al guardar el mantenimiento 🛠️');
      }
    } catch (error) {
      toast.error('Error al guardar el mantenimiento 🛠️');
    }
  }
  render() {
    const { mantenimiento, actividadesDisponibles, mostrarInputOtraActividad, otraActividad, costosActividades } = this.state;
    const fechaValida = this.validarFecha(this.state.mantenimiento?.fecha_mant);
    let mensajeErrorFecha = '';
    if (!fechaValida) {
      mensajeErrorFecha = 'Selecciona una fecha válida en formato dd/mm/yyyy';
    }
    
    return (
      <div className="add-mantenimiento">
        <ToastContainer />
        <h2 className="title is-3">Captura de Mantenimiento</h2>
        <b className="has-text-light">Cantidad Máxima de Actividades por Mes:</b>
        <form style={{ width: '100%' }} onSubmit={this.manejarEnvioDeFormulario} className='p-2 has-text-light'>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Calendar
              onChange={this.cambioFecha}
              value={new Date(this.state.mantenimiento.fecha_mant)}
              id="fecha_mant"
              name="fecha_mant"
              className="has-background-black"
            />
          </div>
          <div>
            <FormControl className='mt-2' style={{ width: '100%' }}>
              <InputLabel htmlFor="fecha_mant" style={{ color: 'white' }}>Fecha del Mantenimiento</InputLabel>
              <Input
                  startAdornment={<Icon className="fas fa-calendar-alt icon is-medium is-size-6"></Icon>}
                  required
                  type="text"
                  name='fecha_mant'
                  id="fecha_mant"
                  onChange={this.cambioFecha}
                  value={this.state.mantenimiento.fecha_mant}
                  className={`input is-hovered has-text-light is-info ${fechaValida ? '' : 'is-danger'}`}
                  readOnly
              />
            </FormControl>
            {mensajeErrorFecha && (
              <b className="help is-danger">{mensajeErrorFecha}</b>
            )}
            <br />
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }} className='mt-2'>
              <b>Selecciona las actividades realizadas:</b>
              {actividadesDisponibles.map((actividad, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        value={actividad}
                        checked={mantenimiento.actividades.includes(actividad)}
                        onChange={this.manejarCheckboxCambio}
                      />
                    }
                    label={actividad}
                  />
                  {mantenimiento.actividades.includes(actividad) && (
                    <FormControl style={{ marginLeft: '20px', width: '30%' }}>
                      <Input
                          startAdornment={<Icon className="fas fa-dollar-sign icon is-medium is-size-6"></Icon>}
                          type="number"
                          name={`costo-${actividad}`}
                          id={`costo-${actividad}`}
                          value={costosActividades[actividad] || ''}
                          onChange={(event) => this.manejarCambioCostoActividad(event, actividad)}
                          className="input is-hovered has-text-light is-success"
                      />
                    </FormControl>
                  )}
                </div>
              ))}
              <div>
                <FormControlLabel
                  control={<Checkbox onChange={this.toggleInputOtraActividad} />}
                  label="Otra Actividad"
                />
                {mostrarInputOtraActividad && (
                  <div>
                    <FormControl className='mt-2' style={{ width: '100%' }}>
                      <InputLabel htmlFor="otra_actividad" style={{ color: 'white' }}>Otra Actividad</InputLabel>
                      <Input
                          startAdornment={<Icon className="fas fa-plus-circle icon is-medium is-size-6"></Icon>}
                          type="text"
                          name='otra_actividad'
                          id="otra_actividad"
                          onChange={this.manejarOtraActividadCambio}
                          value={otraActividad}
                          className="input is-hovered has-text-light is-info"
                      />
                    </FormControl>
                    <br />
                    <FormControl className='mt-2' style={{ width: '100%' }}>
                      <InputLabel htmlFor="costo_otra_actividad" style={{ color: 'white' }}>Costo de la Actividad</InputLabel>
                      <Input
                          startAdornment={<Icon className="fas fa-dollar-sign icon is-medium is-size-6"></Icon>}
                          type="number"
                          name='costo'
                          id="costo"
                          onChange={this.manejarCambio}
                          value={this.state.mantenimiento.costo}
                          className="input is-hovered has-text-light is-success"
                      />
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
            <br />
            <FormControl className='mt-2' style={{ width: '100%' }}>
              <InputLabel htmlFor="coment" style={{ color: 'white' }}>Agrega un comentario (Opcional)</InputLabel>
              <Input
                  startAdornment={<Icon className="fas fa-comment icon is-medium is-size-6"></Icon>}
                  type="text"
                  name='coment'
                  id="coment"
                  onChange={this.manejarCambio}
                  value={this.state.mantenimiento.coment}
                  className="input is-hovered has-text-light is-info" 
              />
            </FormControl>
          </div>
          <input type="hidden" name="action" value={this.state.mantenimiento.action} />
          <input type="hidden" name="id_maq" value={this.state.mantenimiento.maquinaria} />
          <div className="mt-2">
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={!fechaValida}
            >
              <i className="fa-regular fa-floppy-disk icon"></i> Guardar Mantenimiento
            </Button>
            &nbsp;
          </div>
        </form>
      </div>
    );
  }
}

export default AddMantenimiento;
