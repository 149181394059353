import React, { Component } from 'react';
import Servidor from '../../components/Servidor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, IconButton, OutlinedInput, InputAdornment, Icon, Modal, Backdrop, Fade } from '@material-ui/core';

const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'rgba(130, 130, 130, 0.8)',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '0.5rem',
    },
  });
class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            credentials: { nom_usu: '', contra: '' },
            isLoading: false
        };
    }

    manejarCambio = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            credentials: { ...prevState.credentials, [name]: value }
        }));
    };

    toggleMostrarContrasena = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword
        }));
    };

    handleOpen = () => {
        this.setState({ open: true });
    };
    
    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { showPassword, credentials, isLoading } = this.state;
        const { classes } = this.props;
        const { open } = this.state;

        return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end' }}>
            <button style={{ right: "1rem", top: "1rem" }} className="button is-warning is-outlined is-rounded is-inverted" type="button" onClick={this.handleOpen}>
                <Icon className='fas fa-sign-in'></Icon> Iniciar Sesión
            </button>
            <Modal
            className={classes.modal}
            open={open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,   
            }}
            >
                <Fade in={open}>
                    <div style={{ width: '40%' }} className="card has-background-primary-light p-4">
                        <h1 className="card-header title has-background-dark"><p className="card-header-title has-text-light">Iniciar sesión</p></h1>
                        <form className='card-body p-2' onSubmit={this.manejarEnvioDeFormulario}>
                            <ToastContainer />
                            <div>
                                <FormControl style={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Nombre de usuario o Correo Electrónico</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="outlined-adornment-password"
                                        name="nom_usu"
                                        value={credentials.nom_usu}
                                        onChange={this.manejarCambio}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Icon>
                                                    <i className="fas fa-circle-user"></i>
                                                </Icon>
                                            </InputAdornment>
                                        }
                                        label="Nombre de Usuario o Correo Electrónico"
                                    />
                                </FormControl>
                                <FormControl className='mt-3' style={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="outlined-adornment-password"
                                        name="contra"
                                        value={credentials.contra}
                                        onChange={this.manejarCambio}
                                        type={showPassword ? 'text' : 'password'}
                                        label="Contraseña"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.toggleMostrarContrasena}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                >
                                                    {showPassword ? <i className="fas fa-lock-open"></i> : <i className="fas fa-lock"></i>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="form-group mt-2">
                                <button type="submit" className="button is-warning is-rounded" disabled={isLoading}>
                                    <i className="fas fa-sign-in-alt"></i> Iniciar sesión
                                </button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
        );
    }

    manejarEnvioDeFormulario = async (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });

        const { credentials } = this.state;

        const cargaUtil = JSON.stringify({
            nom_usu: credentials.nom_usu,
            contra: credentials.contra,
            action: 'login',
        });

        try {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorUsuarios.php`, {
                method: 'POST',
                body: cargaUtil,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (respuesta.ok) {
                const exitoso = await respuesta.json();
                const token = exitoso.id;
                const nom_usu = exitoso.nom_usu;
                const rol = exitoso.rol;
                localStorage.setItem('token', token);
                localStorage.setItem('nom_usu', nom_usu);
                localStorage.setItem('rol', rol);
                if (exitoso !== false) {
                    toast(
                        <div>
                            <img src="./logo fresh.png" className="App-logo" alt="" /><br />
                            Bienvenido {nom_usu}, Inicio de sesión exitoso ✅<br /> <b style={{ fontSize: '10px' }}>Esperando a que cargue la página...</b>
                        </div>
                        , {
                        position: "top-left",
                        autoClose: 3000,
                        onClose: () => window.location.reload(),
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (typeof this.props.onLogin === 'function') {
                        this.props.onLogin();
                        window.location.href = "";
                    }
                } else {
                    toast.error("Error al iniciar sesión: Datos incorrectos, intente nuevamente");
                }
            } else {
                toast.error("Error en el servidor: " + respuesta.statusText);
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            toast.error('Ocurrió un error al iniciar sesión');
        } finally {
            this.setState({ isLoading: false });
        }
    };
}

export default withStyles(styles)(LoginForm);
