import React, { Component } from 'react';
import Servidor from './Servidor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, Icon, Modal, Backdrop, Fade, Button } from '@material-ui/core';

const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'rgba(130, 130, 130, 0.8)',
      border: '2px solid white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '0.5rem',
    },
});

class ConsumirRefac extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            maquinarias: [], // Inicializa maquinarias como una matriz vacía
            refacusado: {
                id_ref: this.props.id_ref,
                id_maqu: '',
                fecha_use: new Date().toISOString().slice(0, 10),
                cant_use: '',
                costo_use: this.props.costo_refac,
                comentarios: '',
                action: 'guardar'
            }
        };
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const respuesta = await fetch(`${Servidor.url}/controller/ControladorMaquinaria.php`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                action: 'obtener'
            })
        });
        const maquinarias = await respuesta.json();
        this.setState({ maquinarias });
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { open, maquinarias } = this.state;

        return (
            <div className="button btn-icon">
                <button style={{ display: 'flex' }} className="button btn-icon is-link" type="button" onClick={this.handleOpen}>
                    <i class="fa-solid fa-trowel-bricks"></i>
                </button>
                <Modal
                    className={classes.modal}
                    open={open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div id='consumir' className="card has-background-link-light p-4">
                            <h1 className="card-header title has-background-info"><p className="card-header-title has-text-dark">Consumir Refacción</p></h1>
                            <form className='card-body p-2' onSubmit={this.manejarEnvioDeFormulario}>
                                <ToastContainer />
                                <div>
                                    <FormControl style={{ width: '100%' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Refaccion a consumir</InputLabel>
                                        <OutlinedInput
                                            readOnly
                                            value={this.props.nom_refac}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Icon>
                                                        <i className="fas fa-toolbox"></i>
                                                    </Icon>
                                                </InputAdornment>
                                            }
                                            label="Refaccion a consumir"
                                        />
                                    </FormControl>
                                    <input type="hidden" name="id_ref" value={this.state.refacusado.id_ref} />
                                    <div class="field mt-2">
                                        <input
                                            style={{ color: 'black' }}
                                            type="date"
                                            name="fecha_use"
                                            id="fecha_use"
                                            onChange={this.manejarCambio}
                                            value={this.state.refacusado.fecha_use}
                                            className='input is-hovered is-dark has-background-light'
                                        />
                                    </div>
                                    <FormControl style={{ width: '100%' }} variant="outlined">
                                        <InputLabel htmlFor="cant_use">Cantidad a usar</InputLabel>
                                        <OutlinedInput
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Icon>
                                                        <i className="fas fa-hashtag"></i>
                                                    </Icon>
                                                </InputAdornment>
                                            }
                                            type="number"
                                            name='cant_use'
                                            id="cant_use"
                                            label="Cantidad a usar"
                                            onChange={this.manejarCambio}
                                            value={this.state.refacusado.cant_use}
                                        />
                                    </FormControl>
                                    <div className="mt-2"></div>
                                    <FormControl style={{ width: '100%' }} variant="outlined">
                                        <InputLabel htmlFor="costo_use">Costo</InputLabel>
                                        <OutlinedInput
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Icon>
                                                        <i className="fas fa-dollar-sign"></i>
                                                    </Icon>
                                                </InputAdornment>
                                            }
                                            type="number"
                                            name='costo_use'
                                            id="costo_use"
                                            label="Costo"
                                            onChange={this.manejarCambio}
                                            value={this.state.refacusado.costo_use}
                                        />
                                    </FormControl>
                                    <div className="control mt-2">
                                        <select
                                            required
                                            style={{ color: 'black' }}
                                            name='id_maqu'
                                            id="id_maqu"
                                            onChange={this.manejarCambio}
                                            value={this.state.refacusado.id_maqu}
                                            className='input is-hovered is-dark has-background-light'
                                        >
                                            <option value="">Elige la Maquina Asociada</option>
                                            {maquinarias && maquinarias.map(maquina => (
                                                <option key={maquina.id} value={maquina.id}>{maquina.nombre} | Numero: {maquina.num}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mt-2"></div>
                                    <FormControl style={{ width: '100%' }} variant="outlined">
                                        <InputLabel htmlFor="costo_use">Comentarios (opcional)</InputLabel>
                                        <OutlinedInput
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Icon>
                                                        <i className="fas fa-comment"></i>
                                                    </Icon>
                                                </InputAdornment>
                                            }
                                            type="text"
                                            name='comentarios'
                                            id="comentarios"
                                            label="Comentarios (opcional)"
                                            onChange={this.manejarCambio}
                                            value={this.state.refacusado.comentarios}
                                        />
                                    </FormControl>
                                </div>
                                <input type="hidden" name="action" value={this.state.refacusado.action} />
                                <div className="form-group mt-3">
                                    <Button
                                        type="submit"
                                        color='primary'
                                        variant="contained"
                                        startIcon={<i className="fas fa-trowel-bricks"></i>}
                                    >
                                        Consumir refacción
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

    async manejarEnvioDeFormulario(evento) {
        evento.preventDefault();
        // Codificar los datos del formulario como JSON
        const cargaUtil = JSON.stringify(this.state.refacusado);

        // ¡Y enviarlo!
        const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacUsada.php`, {
            method: "POST",
            body: cargaUtil,
        });
        const exitoso = await respuesta.json();
        if (exitoso === true) {
            toast('Refacción consumida 🧰', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                refacusado: {
                    id_ref: this.props.id_ref,
                    id_maqu: '',
                    fecha_use: new Date().toISOString().slice(0, 10),
                    cant_use: '',
                    costo_use: '',
                    comentarios: '',
                    action: 'guardar'
                }
            });
        } else {
            toast.error("Error al consumir la refacción ❌");
        }
    }

    manejarCambio(evento) {
        // Extraer la clave del estado que se va a actualizar, así como el valor
        const clave = evento.target.name;
        let valor = evento.target.value;
        this.setState(state => {
            const refaccionActual = state.refacusado;
            // Actualizamos el valor del usuario, solo en el campo que se haya cambiado
            refaccionActual[clave] = valor;
            return {
                refacusado: refaccionActual,
            }
        });
    }

    handleChange(event) {
        // Actualizar el estado del usuario cuando se seleccione un valor diferente en el Select
        const { id, value } = event.target;
        this.setState(prevState => ({
            refacusado: {
                ...prevState.refacusado,
                [id]: value,
            }
        }));
    }
}

export default withStyles(styles)(ConsumirRefac);