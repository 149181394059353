// searchBar.jsx
import React from 'react';

function SearchBar({ onChange }) {
    return (
        <div className="control has-icons-left">
            <input className="input is-info" type="text" placeholder="Search" onChange={onChange} />
            <span className="icon is-info is-left">
                <i className="fas fa-search" aria-hidden="true"></i>
            </span>
        </div>
    );
}

export default SearchBar;