import React, { useState, useEffect } from 'react';
import Servidor from "../Servidor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemsRefacMaq from './ItemsRefacMaq';
import BarraPage from '../../pages/BarraPage';

function ListaRefacMaq(props) {
    const [refacciones, setRefacciones] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalGastos, setTotalGastos] = useState(0);

    useEffect(() => {
        async function fetchRefacUsada() {
            const respuesta = await fetch(`${Servidor.url}/controller/ControladorRefacUsada.php`, {
                method: "POST",
                body: JSON.stringify({
                    action: "consultar refacciones",
                    id: props.id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await respuesta.json();
            setRefacciones(Array.isArray(data) ? data : []);
        }
        fetchRefacUsada();
    }, [props.id]);

    useEffect(() => {
        const total = refacciones.reduce((accumulator, current) => 
            accumulator + (parseFloat(current.costo_use) || 0) * (parseFloat(current.cant_use) || 0), 
            0
        );
        setTotalGastos(total);
    }, [refacciones]);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    useEffect(() => {
        const total = refacciones
            .filter((refaccion) => {
                return (
                    refaccion.nombre_refaccion.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    refaccion.fecha_use.includes(searchTerm) ||
                    refaccion.cant_use.includes(searchTerm) ||
                    refaccion.costo_use.includes(searchTerm) ||
                    refaccion.comentarios.toLowerCase().includes(searchTerm.toLowerCase())
                );
            })
            .reduce ((accumulator, current) =>
                accumulator + (parseFloat(current.costo_use) || 0) * (parseFloat(current.cant_use) || 0),
                0
            );
        setTotalGastos(total);
    }, [searchTerm, refacciones]);

    return (
        <div className="card has-background-dark p-3" style={{ width: "100%", height: "100%" }}>
            <ToastContainer />
            <BarraPage onSearch={handleSearch} />
            <div id='mantenimientos' className="card mb-1 mt-3" style={{ margin: 0 }}>
                <table className="table is-hoverable is-bordered is-fullwidth has-background-dark">
                    <thead>
                        <tr>
                            <th><i className="fas fa-toolbox icon is-medium"></i>Nombre Refacción</th>
                            <th><i className="fas fa-calendar-day icon is-medium"></i>Fecha de Uso</th>
                            <th><i className="fas fa-hashtag icon is-medium"></i>Cantidad Usada</th>
                            <th><i className="fas fa-dollar-sign icon is-medium"></i>Costo por Uso</th>
                            <th><i className="fas fa-comment icon is-medium"></i>Comentarios</th>
                            <th style={{ width: "15%" }}><i className="fas fa-gear icon is-medium"></i>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {refacciones
                            .filter((refaccion) => {
                                return (
                                    refaccion.nombre_refaccion.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    refaccion.fecha_use.includes(searchTerm) ||
                                    refaccion.cant_use.includes(searchTerm) ||
                                    refaccion.costo_use.includes(searchTerm) ||
                                    refaccion.comentarios.toLowerCase().includes(searchTerm.toLowerCase())
                                );
                            })
                            .map((refaccion) => (
                                <ItemsRefacMaq key={refaccion.id} refaccion={refaccion} />
                            ))}
                    </tbody>
                </table>
            </div>
            <h1 className='tag has-text-dark is-warning title is-5' style={{ margin: 0, width: "100%" }}> 
                Total de Gastos: ${totalGastos} 
            </h1>
        </div>
    );
}

export default ListaRefacMaq;
