import LoginForm from './login/Login';
import { infoVersion } from '../utils/helpers';

const Inicio = ({ isLoggedIn }) => {
  return (
    <div className="container">
      <section className="hero is-fullheight card has-background-dark">
      {!isLoggedIn && <LoginForm />}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className="hero-body">
        <button onClick={infoVersion} className="button is-rounded is-info is-inverted is-outlined"><i className="fas fa-exclamation-circle fa-flip mr-2"></i> Ver. 0.9.0</button>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="container has-text-centered">
            <img className='logo-inicio' src="./logo fresh.png" alt="" />
            <h1 className="title">Bienvenido al inventario de Herramientas</h1>
            <h2 className="subtitle">Aquí podrás gestionar tus herramientas, desde los gastos que estas generando junto a evidencia fotográfica de la misma</h2>
            <h3 className="">En el menú de la izquierda puedes encontrar las herramientas que necesitas para trabajar</h3>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Inicio;
