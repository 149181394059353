import React from "react";
import Servidor from "./Servidor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import EditWindow from "./EditWindow";

class ItemsMaquinaria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eliminado: false
        };
        this.Editar = this.Editar.bind(this);
        this.Eliminar = this.Eliminar.bind(this);
    }
    Editar() {
        return <EditWindow id={this.props.maquinaria.id} />;
    }
    async Eliminar(id) {
        const url = `${Servidor.url}/controller/ControladorMaquinaria.php`;
        const resultado = await Swal.fire({
            title: '¿Deseas eliminar esta maquinaria?',
            text: "No podrás recuperar esta información",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'No'
        });
        if (!resultado.value){
            return;
        }
        const respuesta = await fetch(url, {
            method: "DELETE",
            body: JSON.stringify({
                action: "eliminar", // Identifica la acción como eliminar
                id: id, // Envía el ID de la maquinaria a eliminar
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const exitoso = await respuesta.json();
        if (exitoso === null) {
            toast('Maquinaria Eliminada 🛠️ ', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                eliminado: true,
            });
        } else {
            toast.error("Error eliminando la maquinaria. Intenta de nuevo");
        }
    }

    render() {
        if (this.state.eliminado) {
            return null;
        }
        const rol = localStorage.getItem('rol');
        const botones = (rol === 'Admin' || rol === 'sudo') ? <td><EditWindow id={this.props.maquinaria.id} /><button className="button btn-icon is-danger" onClick={() => this.Eliminar(this.props.maquinaria.id)}><i className="fas fa-trash"></i></button></td> : <td>No tienes permisos</td>;
        return (
            <tr>
                <td>{this.props.maquinaria.id}</td>
                <td><Link className="has-text-info" to={`/ver-maquinaria/${this.props.maquinaria.id}`}>{this.props.maquinaria.nombre}</Link></td>
                <td>{this.props.maquinaria.num}</td>
                <td>{this.props.maquinaria.descripcion}</td>                
                    {botones}
            </tr>
        );
    }
}

export default ItemsMaquinaria;