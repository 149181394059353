import React from "react";
import Servidor from "../Servidor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

class ItemsRefacMaq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eliminado: false
        };
        this.Eliminar = this.Eliminar.bind(this);
    }

    async Eliminar(id) {
        const url = `${Servidor.url}/controller/ControladorRefacUsada.php`;
        const resultado = await Swal.fire({
            title: '¿Deseas eliminar esta Refacción?',
            text: "No podrás recuperar esta información",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminar',
            cancelButtonText: 'No'
        });
        if (!resultado.value) {
            return;
        }

        try {
            const respuesta = await fetch(url, {
                method: "POST", // Cambié de "DELETE" a "POST" si el backend no soporta "DELETE"
                body: JSON.stringify({
                    action: "eliminar", // Identifica la acción como eliminar
                    id: id, // Envía el ID de la refacción a eliminar
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const exitoso = await respuesta.json();
            if (exitoso === null) {
                toast('Refacción Eliminada 🧰', {
                    position: "top-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({
                    eliminado: true,
                });
            } else {
                toast.error("Error eliminando la Refacción. Intenta de nuevo");
            }
        } catch (error) {
            toast.error("Error eliminando la Refacción. Intenta de nuevo");
        }
    }

    render() {
        const rol = localStorage.getItem('rol');
        if (this.state.eliminado) {
            return null;
        }
        let sumaCosto = parseFloat(this.props.refaccion.costo_use) * parseFloat(this.props.refaccion.cant_use);
        return (
            <tr>
                <td>{this.props.refaccion.nombre_refaccion}</td>
                <td>{this.props.refaccion.fecha_use}</td>
                <td>{this.props.refaccion.cant_use}</td>
                <td><p className="tag is-success is-medium has-text-weight-bold"><i className="fas fa-dollar-sign icon"></i> {sumaCosto}</p></td>
                <td>{this.props.refaccion.comentarios}</td>
                <td>
                    {(rol === 'Admin' || rol === 'sudo') ? 
                        <button className="button btn-icon is-danger" onClick={() => this.Eliminar(this.props.refaccion.id)}><i className="fas fa-trash"></i></button> : 
                        <p>No tienes permisos</p>
                    }
                </td>
            </tr>
        );
    }
}

export default ItemsRefacMaq;
