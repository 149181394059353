import React, { Component } from 'react';
import Servidor from '../../components/Servidor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, IconButton, OutlinedInput, InputAdornment, Icon, Modal, Backdrop, Fade, Button } from '@material-ui/core';

const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'rgba(130, 130, 130, 0.8)',
      border: '2px solid white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '0.5rem',
    },
  });
class UserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            isLoading: false,
            usuario : {
                nom_usu: '',
                contra: '',
                email: '',
                rol: '',
                action: 'guardar'
            }
        };
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    validarEmail(email) {
        // Expresión regular para validar el formato del correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    toggleMostrarContrasena = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword
        }));
    };

    handleOpen = () => {
        this.setState({ open: true });
    };
    
    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { showPassword } = this.state;
        const { classes } = this.props;
        const { open } = this.state;

        return (
        <div className="button" style={{ border: '1px solid aqua', width: '100%', padding: '0' }}>
            <button style={{ display: 'flex', width: '100%' }} className="button is-primary is-outlined" type="button" onClick={this.handleOpen}>
                <i className='fas fa-user-plus mr-2 tag is-primary btn-icon is-light'></i> Crear Usuario
            </button>
            <Modal
            className={classes.modal}
            open={open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,   
            }}
            >
                <Fade in={open}>
                    <div style={{ width: '40%' }} className="card has-background-link-light p-4">
                        <h1 className="card-header title has-background-black"><p className="card-header-title has-text-light">Crear Usuario</p></h1>
                        <form className='card-body p-2' onSubmit={this.manejarEnvioDeFormulario}>
                            <ToastContainer />
                            <div>
                                <FormControl style={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Nombre de usuario</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="nom_usu"
                                        name="nom_usu"
                                        value={this.state.usuario.nom_usu}
                                        onChange={this.manejarCambio}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Icon>
                                                    <i className="fas fa-circle-user"></i>
                                                </Icon>
                                            </InputAdornment>
                                        }
                                        label="Nombre de Usuario"
                                    />
                                </FormControl>
                                <FormControl className='mt-3' style={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Correo Electrónico</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="email"
                                        type='email'
                                        name="email"
                                        value={this.state.usuario.email}
                                        onChange={this.manejarCambio}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Icon>
                                                    <i className="fas fa-circle-user"></i>
                                                </Icon>
                                            </InputAdornment>
                                        }
                                        label="Correo Electrónico"
                                    />
                                    {this.state.usuario.email && !this.validarEmail(this.state.usuario.email) && (
                                        <p className="help is-danger">Ingrese un correo electrónico válido</p>
                                    )}
                                </FormControl>
                                <FormControl className='mt-3 mb-3' style={{ width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                    <OutlinedInput
                                        required
                                        color='warning'
                                        id="contra"
                                        name="contra"
                                        value={this.state.usuario.contra}
                                        onChange={this.manejarCambio}
                                        type={showPassword ? 'text' : 'password'}
                                        label="Contraseña"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.toggleMostrarContrasena}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                >
                                                    {showPassword ? <i className="fas fa-lock-open"></i> : <i className="fas fa-lock"></i>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <div className="control">
                                    <select
                                        required
                                        name='rol'
                                        id="rol"
                                        onChange={this.manejarCambio}
                                        value={this.state.usuario.rol}
                                        className="input is-hovered is-warning"
                                    >
                                        <option value="">Elige un rol de usuario</option>
                                        <option value="sudo">sudo</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Visita">Visita</option> 
                                    </select>
                                </div>
                            </div>
                            <input type="hidden" name="action" value={this.state.usuario.action} />
                            <div className="form-group mt-3">
                                <Button 
                                    disabled={!this.validarEmail(this.state.usuario.email)} 
                                    type="submit"
                                    color='primary'
                                    variant="contained"
                                    startIcon={<i className="fas fa-user-plus"></i>}
                                >
                                    Guardar Usuario
                                </Button>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
        );
    }

    async manejarEnvioDeFormulario(evento) {
        evento.preventDefault();
        // Codificar los datos del formulario como JSON
        const cargaUtil = JSON.stringify({
            action: this.state.usuario.action,
            nom_usu: this.state.usuario.nom_usu,
            email: this.state.usuario.email,
            contra: this.state.usuario.contra,
            rol: this.state.usuario.rol,
        });

        // ¡Y enviarlo!
        const respuesta = await fetch(`${Servidor.url}/controller/ControladorUsuarios.php`, {
            method: "POST",
            body: cargaUtil,
        });
        const exitoso = await respuesta.json();
        if (exitoso === true) {
            toast('Usuario Creado 👤', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                usuario: {
                    nom_usu: "",
                    email: "",
                    contra: "",
                    rol: "",
                    action: "guardar" // Restablecer la acción predeterminada después de guardar
                }
            });
        } else {
            toast.error("Error al guardar al usuario 👤");
        }
    }

    manejarCambio(evento) {
        // Extraer la clave del estado que se va a actualizar, así como el valor
        const clave = evento.target.id;
        let valor = evento.target.value;
        this.setState(state => {
            const usuarioActualizado = state.usuario;
            // Actualizamos el valor del usuario, solo en el campo que se haya cambiado
            usuarioActualizado[clave] = valor;
            return {
                usuario: usuarioActualizado,
            }
        });
    }

    handleChange(event) {
        // Actualizar el estado del usuario cuando se seleccione un valor diferente en el Select
        const { id, value } = event.target;
        this.setState(prevState => ({
            usuario: {
                ...prevState.usuario,
                [id]: value,
            }
        }));
    }
}

export default withStyles(styles)(UserModal);
