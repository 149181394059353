import React from "react";
import ViewMaquinaria from "../components/ViewMaquinaria.jsx";
import { useParams } from "react-router-dom";
function MaquinariaPage() {
    const { id } = useParams();
    return (
        <div className="card has-background-dark p-4" style={{ width: "120%", height: "100%" }}>
            <ViewMaquinaria id={id} />
        </div>
    );
}

export default MaquinariaPage;