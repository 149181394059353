import React from "react";
import Servidor from "../Servidor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

class ItemsMantenimiento extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eliminado: false
        };
        this.Eliminar = this.Eliminar.bind(this);
    }
    async Eliminar(id) {
        const url = `${Servidor.url}/controller/ControladorMantenimiento.php`;
        const resultado = await Swal.fire({
            title: '¿Deseas eliminar esta Actividad?',
            text: "No podrás recuperar esta información",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'No'
        });
        if (!resultado.value){
            return;
        }
        const respuesta = await fetch(url, {
            method: "DELETE",
            body: JSON.stringify({
                action: "eliminar", // Identifica la acción como eliminar
                id: id, // Envía el ID de la mantenimiento a eliminar
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const exitoso = await respuesta.json();
        if (exitoso === null) {
            toast('Actividad Eliminada 🛠️ ', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                eliminado: true,
            });
        } else {
            toast.error("Error eliminando la Actividad. Intenta de nuevo");
        }
    }

    render() {
        const rol = localStorage.getItem('rol');
        if (this.state.eliminado) {
            return null;
        }
        return (
            <tr>
                <td>{this.props.mantenimiento.actividad}</td>
                <td>{this.props.mantenimiento.fecha_mant}</td>
                <td><p className="tag is-success is-medium has-text-weight-bold"><i className="fas fa-dollar-sign icon"></i> {this.props.mantenimiento.costo}</p></td>
                <td>{this.props.mantenimiento.coment}</td>
                <td>
                    { (rol === 'Admin' || rol === 'sudo') ? <button className="button btn-icon is-danger" onClick={() => this.Eliminar(this.props.mantenimiento.id)}><i className="fas fa-trash"></i></button> : <p>No tienes permisos</p> }
                </td>
            </tr>
        );
    }
}

export default ItemsMantenimiento;